import { Injectable } from '@angular/core';
import { AuthenticationStoreService } from 'ngx-authentication';
import {
  FilterTranslatorService,
  BaseStoreService,
  UserPermissionsStoreService,
} from 'ngx-common-solution';
import { LocationFilterDto } from 'src/dto/FilterDtos/location-filter-dto';
import { LocationGetDto } from 'src/dto/GetDtos/location-get-dto';
import { LocationService } from 'src/services/http/location.service';
import { LocationPutDto } from 'src/dto/PutDtos/location-put-dto';
import { LocationPostDto } from 'src/dto/PostDtos/location-post-dto';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { merge, Observable, of, switchMap, take, tap } from 'rxjs';
import { GraphLocationInOutGetDto } from 'src/dto/GetDtos/graph-location-in-out-get-dto';
import { LocationTrackingobjectCountDto } from 'src/dto/GetDtos/location-trackingobject-count-dto';
import { PredicateModel } from '@syncfusion/ej2-angular-grids';
import { TranslocoService } from '@ngneat/transloco';

interface CacheItem<T> {
  data: T;
  timestamp: number; // Time when the item was cached
}
@Injectable({
  providedIn: 'root',
})
export class LocationStoreService extends BaseStoreService<
  LocationGetDto,
  LocationPutDto,
  LocationPostDto
> {
  constructor(
    private service: LocationService,
    authenticationStore: AuthenticationStoreService,
    private filterTranslatorService: FilterTranslatorService,
    userPermissionsStore: UserPermissionsStoreService,
    translocoService: TranslocoService,
  ) {
    super(
      authenticationStore,
      service,
      userPermissionsStore,
      EnumPermissions.LocationRead,
      translocoService,
    );
  }
  getByTrackingidentifier(identifier: string) {
    return this.items$.pipe(
      take(1),
      switchMap((items) => {
        const item = items.find((i) =>
          i.trackingidentifiers.includes(identifier),
        );
        if (item) return of(item);
        else return of(undefined);
      }),
    );
  }
  private cacheDuration = 300000;
  // Checks if the cached item is still valid based on the cache duration
  private isCacheValid(timestamp: number): boolean {
    return Date.now() - timestamp < this.cacheDuration;
  }

  private _children = new Map<number, CacheItem<LocationGetDto[]>>();
  setItem<T>(key: number, cache: Map<number, CacheItem<T>>, item: T): void {
    const cacheItem: CacheItem<T> = {
      data: item,
      timestamp: Date.now(),
    };
    cache.set(key, cacheItem);
  }

  // Retrieves an item from the cache if it's not expired
  getItem<T>(
    key: number,
    cache: Map<number, CacheItem<T>>,
  ): Observable<T | undefined> {
    const cacheItem = cache.get(key);
    if (cacheItem && this.isCacheValid(cacheItem.timestamp)) {
      return of(cacheItem.data); // Return as an array
    }
    return of(undefined); // Return undefined if cache is expired or item doesn't exist
  }
  getChildren(id: number): Observable<LocationGetDto[]> {
    return this.getItem(id, this._children).pipe(
      switchMap((items) => {
        if (items) {
          return of(items);
        } else {
          return this.refreshLocationChildren(id);
        }
      }),
    );
  }

  refreshLocationChildren(id: number) {
    return this.service
      .getChildLocations(id)
      .pipe(tap((children) => this.setItem(id, this._children, children)));
  }
  private _objectCount = new Map<
    number,
    CacheItem<LocationTrackingobjectCountDto>
  >();

  getObjectChildren(id: number): Observable<LocationTrackingobjectCountDto> {
    return this.getItem(id, this._objectCount).pipe(
      switchMap((items) => {
        if (items) {
          return of(items);
        } else {
          return this.refreshObjectChildren(id);
        }
      }),
    );
  }
  refreshObjectChildren(id: number) {
    return this.service
      .getChildTrackingobjectsCount(id)
      .pipe(tap((count) => this.setItem(id, this._objectCount, count)));
  }
  private _inOutData = new Map<number, CacheItem<GraphLocationInOutGetDto[]>>();

  getInOutData(id: number): Observable<GraphLocationInOutGetDto[]> {
    return this.getItem(id, this._inOutData).pipe(
      switchMap((items) => {
        if (items) {
          return of(items);
        } else {
          return this.refreshInOutData(id);
        }
      }),
    );
  }
  refreshInOutData(id: number) {
    return this.service
      .getInOuts(id)
      .pipe(tap((data) => this.setItem(id, this._inOutData, data)));
  }
  refreshChildren(id: number) {
    return merge(
      this.refreshLocationChildren(id),
      this.refreshObjectChildren(id),
      this.refreshInOutData(id),
    );
  }
  override getFilterPredicateModel(filterModels: PredicateModel[]) {
    var locationFilter: LocationFilterDto = {};
    filterModels?.forEach((filterModel: PredicateModel) => {
      switch (filterModel.field) {
        case 'id': {
          locationFilter.id =
            this.filterTranslatorService.getNumberFilterTypeByPredicateModel(
              filterModel,
            );
          break;
        }
        case 'name': {
          locationFilter.name =
            this.filterTranslatorService.getTextFilterTypeByPredicateModel(
              filterModel,
            );
          break;
        }
        case 'createdByUserId': {
          locationFilter.createdByUserId =
            this.filterTranslatorService.getSetFilterTypeByPredicateModel<number>(
              filterModel,
              locationFilter.createdByUserId,
            );
          break;
        }
        case 'parentLocationId': {
          locationFilter.parentLocationId =
            this.filterTranslatorService.getSetFilterTypeByPredicateModel<number>(
              filterModel,
              locationFilter.parentLocationId,
            );
          break;
        }
        case 'createdOn': {
          locationFilter.createdOn =
            this.filterTranslatorService.getDateFilterTypeByPredicateModel(
              filterModel,
              locationFilter.createdOn,
            );
          break;
        }
      }
    });
    return Object.keys(locationFilter).length > 0 ? locationFilter : undefined;
  }
}
