import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AuthenticationStoreService } from 'ngx-authentication';
import {
  BaseStoreService,
  UserPermissionsStoreService,
} from 'ngx-common-solution';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { FileGetDto } from 'src/dto/GetDtos/file-get-dto';
import { UserGetDto } from 'src/dto/GetDtos/user-get-dto';
import { UserPostDto } from 'src/dto/PostDtos/user-post-dto';
import { UserPutDto } from 'src/dto/PutDtos/user-put-dto';
import { UserService } from 'src/services/http/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserStoreService extends BaseStoreService<
  UserGetDto,
  UserPutDto,
  UserPostDto
> {
  constructor(
    authenticationStore: AuthenticationStoreService,
    private service: UserService,
    userPermissionsStore: UserPermissionsStoreService,
    translocoService: TranslocoService,
  ) {
    super(
      authenticationStore,
      service,
      userPermissionsStore,
      EnumPermissions.UserRead,
      translocoService,
    );
  }
}
