import { Injectable } from '@angular/core';
import { BaseService } from 'ngx-common-solution';
import { TrackingobjectTypeGetDto } from 'src/dto/GetDtos/trackingobject-type-get-dto';
import { TrackingobjectTypePutDto } from 'src/dto/PutDtos/trackingobject-type-put-dto';
import { TrackingobjectTypePostDto } from 'src/dto/PostDtos/trackingobject-type-post-dto';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrackingobjectTypeService extends BaseService<
  TrackingobjectTypeGetDto,
  TrackingobjectTypePutDto,
  TrackingobjectTypePostDto
> {
  constructor(http: HttpClient) {
    super(environment.apiBaseUrl, http, 'trackingobjecttype');
  }
  getByTrackingidentifier(
    identifier: string,
  ): Observable<TrackingobjectTypeGetDto> {
    return this.http.get<TrackingobjectTypeGetDto>(
      environment.apiBaseUrl + this.path + '/trackingidentifier/' + identifier,
    );
  }
}
