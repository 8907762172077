import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  NgControl,
} from '@angular/forms';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Subject, map } from 'rxjs';
import { getDefaultSelectorConfigObjectWithExternalIds } from 'src/configs/trackingobject-selector-config';
import { TrackingobjectGetDto } from 'src/dto/GetDtos/trackingobject-get-dto';
import { TrackingobjectAddModel } from 'src/services/util/trackingobject-add-model';
import { TrackingobjectStoreService } from 'src/stores/trackingobject-store.service';
import { SearchModel } from 'src/services/util/search-model';
import { SearchInputComponent } from '../search-input/search-input.component';

@Component({
  selector: 'app-object-add-multiple',
  templateUrl: './object-add-multiple.component.html',
  styleUrls: ['./object-add-multiple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectAddMultipleComponent
  implements OnDestroy, ControlValueAccessor
{
  private onDestroy$ = new Subject();
  @ViewChild('searchInput') searchInput?: SearchInputComponent;
  @Output() modelsChanged = new EventEmitter<TrackingobjectAddModel[]>();
  @Input() additionalAction?: {
    iconClass: string;
    style?: string;
    tooltip?: { confirmed: string; unconfirmed: string };
  };
  @Input() createNew?: boolean | null = false;
  @Input() enabled = true;
  @Input() initialFocus?: boolean;
  @Input() exceptedObjectIds: number[] = []; 
  selectorConfigObject = getDefaultSelectorConfigObjectWithExternalIds(
    this.objectStore,
  );
  private transferObjectModelSubject = new Subject();
  trackingobjectIds$ = this.transferObjectModelSubject.pipe(
    map((r) => {
      return [
        ...this.exceptedObjectIds,
        ...(this.models
          ?.filter((c) => c !== undefined)
          .map((c) => c!.trackingobjectId) ?? []),
      ];
    }),
  );
  notFoundError: boolean = false;
  ngControl?: NgControl;
  isDisabled?: boolean;
  models: TrackingobjectAddModel[] | undefined;
  constructor(
    @Optional() @Self() _ngControl: NgControl,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    public objectStore: TrackingobjectStoreService,
  ) {
    if (!!_ngControl) {
      _ngControl.valueAccessor = this;
    }
    this.ngControl = _ngControl;
  }
  writeValue(obj: TrackingobjectAddModel[] | undefined) {
    if (obj && obj.length > 0) {
      this.models = obj;
    } else {
      this.models = undefined;
    }
    this.transferObjectModelSubject.next(true);
  }
  onChange: any = (value: any) => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  public focus() {
    if (this.searchInput) {
      this.searchInput.focus();
    }
  }
  onSearchCompleted(search: SearchModel | undefined) {
    if (search && search.trackingobject)
      this.addTrackingobject(search.trackingobject);
  }
  addTrackingobject(object: TrackingobjectGetDto) {
    const newModel: TrackingobjectAddModel = {
      trackingobjectId: object.id,
      error: undefined,
    };
    this.models = this.models ?? [];
    if (
      !this.models.find((m) => m.trackingobjectId == object.id)
    ) {
      this.models = [...this.models, newModel];
      this.transferObjectModelSubject.next(true);
      this.cdr.detectChanges();
      this.sendObjects();
    }
  }
  removeTrackingobject(trackingObjectId: number) {
    if (this.models) {
      this.models = this.models.filter(
        (model) => model?.trackingobjectId != trackingObjectId,
      );
      this.transferObjectModelSubject.next(true);
      this.cdr.detectChanges();
      this.sendObjects();
    }
  }
  sendObjects() {
    this.onChange(this.models);
    this.modelsChanged.emit(this.models ?? []);
  }

  onErrorClick(error: string) {
    DialogUtility.alert(error);
  }
  onBeforeOpenObjects(e: any) {
    this.transferObjectModelSubject.next(true);
  }
  onAdditionalActionClick(actionModel: TrackingobjectAddModel) {
    if (this.models) {
      this.models = this.models.map((m) =>
        m.trackingobjectId == actionModel.trackingobjectId
          ? { ...actionModel, additionalAction: !actionModel.additionalAction }
          : m,
      );
      this.sendObjects();
    }
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
