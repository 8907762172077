import { Injectable } from '@angular/core';
import { TrackingobjectTypeGetDto } from 'src/dto/GetDtos/trackingobject-type-get-dto';
import { TrackingobjectTypePutDto } from 'src/dto/PutDtos/trackingobject-type-put-dto';
import { TrackingobjectTypePostDto } from 'src/dto/PostDtos/trackingobject-type-post-dto';
import { AuthenticationStoreService } from 'ngx-authentication';
import { TrackingobjectTypeService } from 'src/services/http/trackingobject-type.service';
import {
  BaseStoreService,
  UserPermissionsStoreService,
} from 'ngx-common-solution';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class TrackingobjectTypeStoreService extends BaseStoreService<
  TrackingobjectTypeGetDto,
  TrackingobjectTypePutDto,
  TrackingobjectTypePostDto
> {
  constructor(
    authenticationStore: AuthenticationStoreService,
    private service: TrackingobjectTypeService,
    userPermissionsStore: UserPermissionsStoreService,
    translocoService: TranslocoService,
  ) {
    super(
      authenticationStore,
      service,
      userPermissionsStore,
      EnumPermissions.CoreDataRead,
      translocoService,
    );
  }
  getByTrackingidentifier(identifier: string) {
    return this.service.getByTrackingidentifier(identifier);
  }
}
