import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { UserSettingGetDto } from 'src/dto/GetDtos/user-setting-get-dto';
import { SettingStoreService } from 'src/stores/setting-store.service';
import { EnumSettingTypes } from 'src/dto/Enums/enum-setting-types';
import { LocationGetDto } from 'src/dto/GetDtos/location-get-dto';
import { LocationStoreService } from 'src/stores/location-store.service';
import { TrackingobjectGetDto } from 'src/dto/GetDtos/trackingobject-get-dto';
import { TrackingobjectStoreService } from 'src/stores/trackingobject-store.service';
import { SettingGetDto } from 'src/dto/GetDtos/setting-get-dto';
import { getDefaultSelectorConfigLocation } from 'src/configs/location-selector-config';
import { getDefaultSelectorConfigObjectWithExternalIds } from 'src/configs/trackingobject-selector-config';
import { getDefaultSelectorConfigObjectType } from 'src/configs/trackingobject-type-selector-config';
import { TrackingobjectTypeStoreService } from 'src/stores/trackingobject-type-store.service';
import { TrackingobjectTypeGetDto } from 'src/dto/GetDtos/trackingobject-type-get-dto';
import { TeamSettingGetDto } from 'src/dto/GetDtos/team-setting-get-dto';
import { SettingDetailsService } from './setting-details.service';

@Component({
  selector: 'app-setting-details',
  templateUrl: './setting-details.component.html',
  styleUrls: ['./setting-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingDetailsComponent {
  EP = EnumPermissions;
  EnumSettingTypes = EnumSettingTypes;
  private onDestroy$ = new Subject();
  @Input() loading = false;
  @Input() writePermission: EnumPermissions = EnumPermissions.Unknown;
  @Input() set itemId(itemId: number | undefined | null) {
    this.formGroup.patchValue({ itemId: itemId });
  }
  @Input() set itemSetting(
    itemSetting: UserSettingGetDto | TeamSettingGetDto | undefined,
  ) {
    this.detailsService.setItemSetting(itemSetting);
  }
  @Input() set excludedItemSettings(
    itemSettings: UserSettingGetDto[] | TeamSettingGetDto[] | null,
  ) {
    this.detailsService.setExcludedSettings(itemSettings ?? []);
  }
  @Output() itemSettingChanged = new EventEmitter<
    UserSettingGetDto | TeamSettingGetDto
  >();
  @Output() itemSettingDeleted = new EventEmitter<
    UserSettingGetDto | TeamSettingGetDto
  >();
  formGroup = this.fb.group({
    itemId: new FormControl<number | undefined>(undefined, [
      Validators.required,
    ]),
    settingId: new FormControl<number | undefined>(undefined, [
      Validators.required,
    ]),
    value: new FormControl<any | undefined>('', [Validators.required]),
    defaultValue: new FormControl<string | undefined>(''),
    possibleValues: new FormControl<string | undefined>(''),
    locationName: new FormControl<string | undefined>(''),
    trackingobjectName: new FormControl<string | undefined>(''),
    trackingobjectTypeName: new FormControl<string | undefined>(''),
  });
  settings$: Observable<SettingGetDto[]>;
  selectedSetting$;
  itemSetting$;
  constructor(
    private fb: FormBuilder,
    private detailsService: SettingDetailsService,
    private settingStore: SettingStoreService,
    private locationStore: LocationStoreService,
    private objectStore: TrackingobjectStoreService,
    private typeStore: TrackingobjectTypeStoreService,
  ) {
    this.settings$ = this.settingStore.settings$;
    this.selectedSetting$ = this.detailsService.setting$;
    this.itemSetting$ = this.detailsService.itemSetting$;
    this.settings$ = combineLatest([
      this.detailsService.excludedItemSettings$.pipe(
        map((itemSettings) => itemSettings.map((tset) => tset.settingId)),
      ),
      this.settings$,
      this.itemSetting$,
    ]).pipe(
      map(([itemSettings, settings, itemSetting]) => {
        return settings.filter(
          (setting) =>
            (itemSetting ? itemSetting.settingId == setting.id : false) ||
            !itemSettings.includes(setting.id),
        );
      }),
    );

    this.formGroup.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        map((value) => value.settingId),
        distinctUntilChanged(),
        switchMap((settingId) =>
          this.settings$.pipe(
            take(1),
            map((settings) =>
              settings.find((setting) => setting.id == settingId),
            ),
          ),
        ),
      )
      .subscribe((setting) => {
        this.detailsService.setSetting(setting);
      });
    this.detailsService.itemSetting$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((r) => {
        if (r) {
          this.formGroup.patchValue(r);
        } else
          this.formGroup.patchValue({
            settingId: undefined,
            value: '',
            defaultValue: '',
            possibleValues: '',
            locationName: '',
            trackingobjectName: '',
            trackingobjectTypeName: '',
          });
      });
    combineLatest([this.selectedSetting$, this.detailsService.itemSetting$])
      .pipe(
        takeUntil(this.onDestroy$),
        filter(
          ([setting, itemSetting]) =>
            !!itemSetting && !!setting && itemSetting.settingId == setting.id,
        ),
        distinctUntilChanged(),
        switchMap(([setting, itemSetting]) => {
          if (itemSetting && setting && itemSetting.value) {
            switch (setting.settingTypeId) {
              case EnumSettingTypes.LocationId: {
                const value = new Number(itemSetting.value);
                return this.locationStore.getSingle(value.valueOf()).pipe(
                  map((location) => {
                    return { ...itemSetting, locationName: location.name };
                  }),
                );
              }
              case EnumSettingTypes.TrackingobjectId: {
                const value = new Number(itemSetting.value);
                return this.objectStore.getSingle(value.valueOf()).pipe(
                  map((object) => {
                    return { ...itemSetting, trackingobjectName: object.displayName };
                  }),
                );
              }
              case EnumSettingTypes.TrackingobjectTypeId: {
                const value = new Number(itemSetting.value);
                return this.typeStore.getSingle(value.valueOf()).pipe(
                  map((type) => {
                    return {
                      ...itemSetting,
                      trackingobjectTypeName: type.name,
                    };
                  }),
                );
              }
              case EnumSettingTypes.bool: {
                const value = new Boolean(itemSetting.value);
                return of({ ...itemSetting, value: value.valueOf() });
              }
              case EnumSettingTypes.datetime: {
                const value = new Date(itemSetting.value);
                return of({ ...itemSetting, value: value });
              }
            }
          }
          return of(itemSetting);
        }),
      )
      .subscribe((r) => {
        if (r) this.formGroup.patchValue(r);
      });
  }
  onSaveClick(selectedSetting: SettingGetDto) {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    if (this.formGroup.value?.itemId && this.formGroup.value.settingId) {
      const itemSetting = {
        userId: this.formGroup.value.itemId,
        teamId: this.formGroup.value.itemId,
        settingId: this.formGroup.value.settingId,
        value:
          selectedSetting.settingTypeId == EnumSettingTypes.datetime
            ? this.formGroup.value.value?.toISOString()
            : this.formGroup.value.value?.toString(),
        possibleValues: selectedSetting.possibleValues,
        defaultValue: selectedSetting.defaultValue,
        name: selectedSetting.name,
      } as UserSettingGetDto | TeamSettingGetDto;
      this.itemSettingChanged.emit(itemSetting);
    }
  }
  onDeleteClick(itemSetting: UserSettingGetDto | TeamSettingGetDto) {
    if (itemSetting) {
      this.itemSettingDeleted.emit(itemSetting);
    }
  }
  selectorConfigLocation = getDefaultSelectorConfigLocation(this.locationStore);
  onLocationSelected(location: LocationGetDto) {
    this.formGroup.patchValue({
      value: location.id.toString(),
      locationName: location.name,
    });
  }
  selectorConfigTrackingobject = getDefaultSelectorConfigObjectWithExternalIds(
    this.objectStore,
  );
  onTrackingobjectSelected(trackingobject: TrackingobjectGetDto) {
    this.formGroup.patchValue({
      value: trackingobject.id.toString(),
      trackingobjectName: trackingobject.displayName,
    });
  }
  selectorConfigTrackingobjectType = getDefaultSelectorConfigObjectType(
    this.typeStore,
  );
  onTrackingobjectTypeSelected(trackingobjectType: TrackingobjectTypeGetDto) {
    this.formGroup.patchValue({
      value: trackingobjectType.id.toString(),
      trackingobjectTypeName: trackingobjectType.name,
    });
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
