<ng-container *checkPermissions="let c">
  <form [formGroup]="formGroup" class="centered-form ml-3">
    <fieldset
      class="fieldset"
      [disabled]="(c(writePermission) | async) ? null : 'disabled'"
      style="border: none"
    >
      <ejs-dropdownlist
        [dataSource]="settings$ | async"
        [fields]="{ text: 'name', value: 'id' }"
        [enabled]="c(writePermission) | async"
        placeholder="Setting"
        floatLabelType="Auto"
        formControlName="settingId"
      ></ejs-dropdownlist>

      <ng-container *ngIf="selectedSetting$ | async as selectedSetting">
        <ejs-dropdownlist
          *ngIf="selectedSetting.possibleValues"
          [dataSource]="selectedSetting.possibleValues"
          [fields]="{ text: 'name', value: 'id' }"
          [enabled]="true"
          placeholder="Wert"
          floatLabelType="Auto"
          formControlName="value"
        ></ejs-dropdownlist>
        <ng-container
          *ngIf="!selectedSetting.possibleValues"
          [ngSwitch]="selectedSetting.settingTypeId"
        >
          <sol-textbox
            *ngSwitchCase="EnumSettingTypes.string"
            label="Wert"
            controlName="value"
          >
            <input
              class="e-input"
              type="text"
              name="value"
              formControlName="value"
            />
          </sol-textbox>
          <div *ngSwitchCase="EnumSettingTypes.bool">
            <label class="mr-3">Wert</label>
            <div class="mt-1 align-sub">
              <ejs-switch
                onLabel="JA"
                offLabel="NEIN"
                formControlName="value"
              ></ejs-switch>
            </div>
          </div>
          <sol-textbox
            *ngSwitchCase="EnumSettingTypes.int"
            label="Wert"
            controlName="value"
          >
            <input
              class="e-input"
              type="number"
              inputmode="number"
              step="1"
              pattern="\d*"
              name="value"
              formControlName="value"
            />
          </sol-textbox>
          <sol-textbox
            *ngSwitchCase="EnumSettingTypes.double"
            label="Wert"
            controlName="value"
          >
            <input
              class="e-input"
              type="number"
              step="0.01"
              name="value"
              formControlName="value"
            />
          </sol-textbox>
          <ejs-datetimepicker
            *ngSwitchCase="EnumSettingTypes.datetime"
            formControlName="value"
            placeholder="Wert"
            floatLabelType="Auto"
            format="dd MMMM yyyy"
          ></ejs-datetimepicker>
          <sol-textbox
            *ngSwitchCase="EnumSettingTypes.LocationId"
            fontIcon="ellipsis-vertical"
            (iconClick)="selectorLocation.visible = true"
            label="Ort"
            controlName="locationName"
            [linkFormat]="'/location/{0}'"
            [linkItems]="['value']"
            [form]="formGroup"
          >
          </sol-textbox>
          <sol-textbox
            *ngSwitchCase="EnumSettingTypes.TrackingobjectId"
            fontIcon="ellipsis-vertical"
            (iconClick)="selectorTrackingobject.visible = true"
            label="Trackingobjekt"
            controlName="trackingobjectName"
            [linkFormat]="'/object/{0}'"
            [linkItems]="['value']"
            [form]="formGroup"
          >
          </sol-textbox>
          <sol-textbox
            *ngSwitchCase="EnumSettingTypes.TrackingobjectTypeId"
            fontIcon="ellipsis-vertical"
            (iconClick)="selectorTrackingobjectType.visible = true"
            label="Trackingobjekt-Typ"
            controlName="trackingobjectTypeName"
            [linkFormat]="'/objecttype/{0}'"
            [linkItems]="['value']"
            [form]="formGroup"
          >
          </sol-textbox>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="c(writePermission) | async">
        <div class="my-3 flex gap-2">
          <button
            *ngIf="selectedSetting$ | async as selectedSetting"
            [disabled]="loading"
            ejs-button
            type="submit"
            name="button"
            class="icon-button"
            (click)="onSaveClick(selectedSetting)"
          >
            <div>
              <i class="fa-regular fa-floppy-disk"></i>
              Speichern
            </div>
          </button>
          <button
            *ngIf="itemSetting$ | async as itemSetting"
            [disabled]="loading"
            (click)="onDeleteClick(itemSetting)"
            ejs-button
            type="button"
            name="button"
            class="icon-button"
          >
            <div>
              <i class="fa-regular fa-trash-can"></i>
              Löschen
            </div>
          </button>
        </div>
      </ng-container>
    </fieldset>
  </form>
</ng-container>
<ejs-dialog
  #selectorLocation
  [showCloseIcon]="true"
  header="Ort auswählen"
  width="600"
  [visible]="false"
  isModal="true"
  (overlayClick)="selectorLocation.hide()"
>
  <ng-template #content>
    <sol-selector
      [selectorConfig]="selectorConfigLocation"
      (itemSelect)="
        onLocationSelected($event); selectorLocation.visible = false
      "
    >
    </sol-selector>
  </ng-template>
</ejs-dialog>

<ejs-dialog
  #selectorTrackingobject
  [showCloseIcon]="true"
  header="Trackingobjekt auswählen"
  width="600"
  [visible]="false"
  isModal="true"
  (overlayClick)="selectorTrackingobject.hide()"
>
  <ng-template #content>
    <sol-selector
      [selectorConfig]="selectorConfigTrackingobject"
      (itemSelect)="
        onTrackingobjectSelected($event); selectorTrackingobject.visible = false
      "
    >
    </sol-selector>
  </ng-template>
</ejs-dialog>

<ejs-dialog
  #selectorTrackingobjectType
  [showCloseIcon]="true"
  header="Trackingobjekt-Typ auswählen"
  width="600"
  [visible]="false"
  isModal="true"
  (overlayClick)="selectorTrackingobjectType.hide()"
>
  <ng-template #content>
    <sol-selector
      [selectorConfig]="selectorConfigTrackingobjectType"
      (itemSelect)="
        onTrackingobjectTypeSelected($event);
        selectorTrackingobjectType.visible = false
      "
    >
    </sol-selector>
  </ng-template>
</ejs-dialog>
