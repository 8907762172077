import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { RoleGetDto } from 'src/dto/GetDtos/role-get-dto';
import { SystemRoleGetDto } from 'src/dto/GetDtos/system-role-get-dto';
import { UserPermissionGetDto } from 'src/dto/GetDtos/user-permission-get-dto';
import {
  PermissionModel,
  PermissionModels,
} from 'src/services/util/permission-group';

enum PermissionTypes {
  Read,
  Write,
  Delete,
  All,
}
@Component({
  selector: 'app-permission-selector',
  templateUrl: './permission-selector.component.html',
  styleUrls: ['./permission-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionSelectorComponent implements OnChanges {
  PermissionTypes = PermissionTypes;
  @Input() selectedPermissions?: UserPermissionGetDto | null;
  @Input() selectedRoles?: RoleGetDto[] | null;
  @Input() selectedSystemRoles?: SystemRoleGetDto[] | null;
  @Input() readOnly?: boolean | null;
  @Output() selectionChanged = new EventEmitter<number[]>();
  permissionModels: PermissionModel[];
  allReadChecked: boolean = false;
  allWriteChecked: boolean = false;
  allDeleteChecked: boolean = false;
  constructor() {
    this.permissionModels = [...PermissionModels];
    if (this.selectedPermissions)
      this.setSinglePermissions(this.selectedPermissions.permissionIds);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedPermissions'] && this.selectedPermissions) {
      this.permissionModels = this.permissionModels.map((m) => {
        return {
          ...m,
          readChecked: {
            ...m.readChecked,
            userChecked:
              m.readPermission &&
              this.selectedPermissions!.permissionIds.includes(
                m.readPermission,
              ),
          },
          writeChecked: {
            ...m.writeChecked,
            userChecked:
              m.writePermission &&
              this.selectedPermissions!.permissionIds.includes(
                m.writePermission,
              ),
          },
          deleteChecked: {
            ...m.deleteChecked,
            userChecked:
              m.deletePermission &&
              this.selectedPermissions!.permissionIds.includes(
                m.deletePermission,
              ),
          },
        } as PermissionModel;
      });
    }
    if (changes['selectedRoles'] && this.selectedRoles) {
      this.permissionModels = this.permissionModels.map((m) => {
        return {
          ...m,
          readChecked: {
            ...m.readChecked,
            roles:
              this.selectedRoles?.filter(
                (role) =>
                  m.readPermission &&
                  role.permissions.includes(m.readPermission),
              ) ?? [],
            userChecked: m.readChecked.userChecked,
          },
          writeChecked: {
            ...m.writeChecked,
            roles:
              this.selectedRoles?.filter(
                (role) =>
                  m.writePermission &&
                  role.permissions.includes(m.writePermission),
              ) ?? [],
            userChecked: m.writeChecked.userChecked,
          },
          deleteChecked: {
            ...m.deleteChecked,
            roles:
              this.selectedRoles?.filter(
                (role) =>
                  m.deletePermission &&
                  role.permissions.includes(m.deletePermission),
              ) ?? [],
            userChecked: m.deleteChecked.userChecked,
          },
        } as PermissionModel;
      });
    }
    if (changes['selectedSystemRoles'] && this.selectedSystemRoles) {
      this.permissionModels = this.permissionModels.map((m) => {
        return {
          ...m,
          readChecked: {
            ...m.readChecked,
            systemRoles:
              this.selectedSystemRoles?.filter(
                (role) =>
                  m.readPermission &&
                  role.permissions.includes(m.readPermission),
              ) ?? [],
            userChecked: m.readChecked.userChecked,
          },
          writeChecked: {
            ...m.writeChecked,
            systemRoles:
              this.selectedSystemRoles?.filter(
                (role) =>
                  m.writePermission &&
                  role.permissions.includes(m.writePermission),
              ) ?? [],
            userChecked: m.writeChecked.userChecked,
          },
          deleteChecked: {
            ...m.deleteChecked,
            systemRoles:
              this.selectedSystemRoles?.filter(
                (role) =>
                  m.deletePermission &&
                  role.permissions.includes(m.deletePermission),
              ) ?? [],
            userChecked: m.deleteChecked.userChecked,
          },
        } as PermissionModel;
      });
    }
  }
  onAllChange(e: any) {
    if (this.readOnly) {
      e.preventDefault();
      return;
    }
    const permissionType = e.currentTarget.value as string;
    const checked = e.currentTarget.checked as boolean;
    if (permissionType == PermissionTypes.Read.toString()) {
      this.allReadChecked = checked;
      this.allWriteChecked = !checked ? false : this.allWriteChecked;
      this.allDeleteChecked = !checked ? false : this.allDeleteChecked;
      this.permissionModels = this.permissionModels.map((m) => {
        return {
          ...m,
          readChecked: { ...m.readChecked, userChecked: checked },
          writeChecked: {
            ...m.writeChecked,
            userChecked: !checked ? false : m.writeChecked.userChecked,
          },
          deleteChecked: {
            ...m.deleteChecked,
            userChecked: !checked ? false : m.deleteChecked.userChecked,
          },
        };
      });
    } else if (permissionType == PermissionTypes.Write.toString()) {
      this.allReadChecked = checked ? true : this.allReadChecked;
      this.allWriteChecked = checked;
      this.allDeleteChecked = !checked ? false : this.allDeleteChecked;
      this.permissionModels = this.permissionModels.map((m) => {
        return {
          ...m,
          readChecked: {
            ...m.readChecked,
            userChecked: checked ? true : m.readChecked.userChecked,
          },
          writeChecked: { ...m.writeChecked, userChecked: checked },
          deleteChecked: {
            ...m.deleteChecked,
            userChecked: !checked ? false : m.deleteChecked.userChecked,
          },
        };
      });
    } else if (permissionType == PermissionTypes.Delete.toString()) {
      this.allReadChecked = checked ? true : this.allReadChecked;
      this.allWriteChecked = checked ? true : this.allWriteChecked;
      this.allDeleteChecked = checked;
      this.permissionModels = this.permissionModels.map((m) => {
        return {
          ...m,
          readChecked: {
            ...m.readChecked,
            userChecked: checked ? true : m.readChecked.userChecked,
          },
          writeChecked: {
            ...m.writeChecked,
            userChecked: checked ? true : m.writeChecked.userChecked,
          },
          deleteChecked: { ...m.deleteChecked, userChecked: checked },
        };
      });
    }
    this.setSelectedPermissions();
  }

  onSinglePermissionChange(e: any, permission?: EnumPermissions) {
    if (this.readOnly) {
      e.preventDefault();
      e.currentTarget.checked = !e.currentTarget.checked;
      return;
    }
    const permissionType = e.currentTarget.value as string;
    const checked = e.currentTarget.checked as boolean;
    if (permission) {
      if (permissionType == PermissionTypes.Read.toString()) {
        this.permissionModels = this.permissionModels.map((m) => {
          if (m.readPermission != permission) return m;
          return {
            ...m,
            readChecked: { ...m.readChecked, userChecked: checked },
            writeChecked: {
              ...m.writeChecked,
              userChecked: checked ? m.writeChecked.userChecked : false,
            },
            deleteChecked: {
              ...m.deleteChecked,
              userChecked: checked ? m.deleteChecked.userChecked : false,
            },
          };
        });
      } else if (permissionType == PermissionTypes.Write.toString()) {
        this.permissionModels = this.permissionModels.map((m) => {
          if (m.writePermission != permission) return m;
          return {
            ...m,
            readChecked: {
              ...m.readChecked,
              userChecked: checked ? true : m.readChecked.userChecked,
            },
            writeChecked: { ...m.writeChecked, userChecked: checked },
            deleteChecked: {
              ...m.deleteChecked,
              userChecked: checked ? m.deleteChecked.userChecked : false,
            },
          };
        });
      } else if (permissionType == PermissionTypes.Delete.toString()) {
        this.permissionModels = this.permissionModels.map((m) => {
          if (m.deletePermission != permission) return m;
          return {
            ...m,
            readChecked: {
              ...m.readChecked,
              userChecked: checked ? true : m.readChecked.userChecked,
            },
            writeChecked: {
              ...m.writeChecked,
              userChecked: checked ? true : m.writeChecked.userChecked,
            },
            deleteChecked: { ...m.deleteChecked, userChecked: checked },
          };
        });
      }
      this.allReadChecked = this.permissionModels.every((m) => m.readChecked);
      this.allWriteChecked = this.permissionModels.every((m) => m.writeChecked);
      this.allDeleteChecked = this.permissionModels.every(
        (m) => m.deleteChecked,
      );
      this.setSelectedPermissions();
    }
  }
  setSinglePermissions(selectedPermissions: EnumPermissions[]) {
    this.permissionModels = this.permissionModels.map((m) => {
      return {
        ...m,
        readChecked: {
          ...m.readChecked,
          userChecked:
            m.readPermission &&
            (selectedPermissions!.includes(m.readPermission) ||
              (m.writePermission &&
                selectedPermissions!.includes(m.writePermission)) ||
              (m.deletePermission &&
                selectedPermissions!.includes(m.deletePermission))),
        },
        writeChecked: {
          ...m.writeChecked,
          userChecked:
            m.writePermission &&
            (selectedPermissions!.includes(m.writePermission) ||
              (m.deletePermission &&
                selectedPermissions!.includes(m.deletePermission))),
        },
        deleteChecked: {
          ...m.deleteChecked,
          userChecked:
            m.deletePermission &&
            selectedPermissions!.includes(m.deletePermission),
        },
      } as PermissionModel;
    });

    this.setSelectedPermissions();
  }
  setSelectedPermissions() {
    const selectedPermissions = [
      ...new Set(
        this.permissionModels
          .flatMap((m) => [
            m.readChecked.userChecked ? m.readPermission : undefined,
            m.writeChecked.userChecked ? m.writePermission : undefined,
            m.deleteChecked.userChecked ? m.deletePermission : undefined,
          ])
          .filter((m) => m !== undefined)
          .map((m) => m!),
      ),
    ];
    this.selectionChanged.emit(selectedPermissions);
  }
}
