import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  distinctUntilChanged,
  shareReplay,
  tap,
} from 'rxjs';
import { Item } from 'ngx-common-solution';

@Injectable({
  providedIn: 'root',
})
export class TeamUserListService<TGetDto extends Item> {
  protected onDestroy$ = new Subject();
  private _teamUsers$ = new ReplaySubject<TGetDto[]>(1);
  teamUsers: TGetDto[] = [];
  teamUsers$: Observable<TGetDto[]> = this._teamUsers$.pipe(
    tap((r) => (this.teamUsers = r)),
    shareReplay(1),
  );
  private _item$: BehaviorSubject<TGetDto | undefined> = new BehaviorSubject<
    TGetDto | undefined
  >(undefined);
  item$: Observable<TGetDto | undefined> = this._item$.asObservable().pipe(
    distinctUntilChanged(),
    tap((item) => (this.item = item)),
    shareReplay(1),
  );
  item: TGetDto | undefined;
  constructor() {}
  setItem(item: TGetDto) {
    this._item$.next(item);
  }
  setTeamUsers(items: TGetDto[]) {
    this._teamUsers$.next(items);
  }
  addOrUpdateTeamUser(teamUser: TGetDto) {
    if (!this.teamUsers.find((l) => l.id == teamUser.id)) {
      this._teamUsers$.next([...this.teamUsers, teamUser]);
    } else {
      this._teamUsers$.next(
        this.teamUsers.map((t) =>
          t.id == teamUser.id ? { ...t, ...teamUser } : t,
        ),
      );
    }
  }
  removeTeamUser(id: number) {
    this._teamUsers$.next(this.teamUsers.filter((r) => r.id != id));
  }
}
