<div (submit)="(false)" class="flex items-start">
  <sol-textbox
    [label]="header ?? 'Suchen'"
    inputClasses="rounded-l rounded-r-none w-80"
    [controlName]="ngControl?.name?.toString() ?? undefined"
    [labelTop]="!!placeholder"
  >
    <div
      class="flex h-7 w-full justify-between pl-3"
      *ngIf="(model && hideInput && showResult && !multiple) || !enabled"
      (click)="onLinkDivClick()"
    >
      <a
        *ngIf="model?.locationId"
        class="link-input my-auto"
        [routerLink]="'/location/dashboard'"
        [queryParams]="{
          locationId: model?.locationId
        }"
      >
        {{ model?.locationName }}
      </a>
      <a
        *ngIf="model?.trackingobjectId"
        class="link-input my-auto"
        [routerLink]="'/object/search'"
        [queryParams]="{
          objectId: model?.trackingobjectId
        }"
      >
        {{ model?.trackingobjectName }}
      </a>
      <a
        *ngIf="model?.addressId"
        class="link-input my-auto"
        [routerLink]="'/address/' + model?.addressId"
      >
        {{ model?.addressName }}
      </a>
      <button
        *ngIf="
          model?.locationName || model?.trackingobjectName || model?.addressName
        "
        class="relative block h-max rounded-none border-y-0 border-r-0 shadow-none"
        ejs-button
        (click)="onResetClick()"
        type="button"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    </div>
    <div
      class="flex h-7 w-full justify-between pl-3"
      (click)="onLinkDivClick()"
      *ngIf="hideInput && !model && placeholder"
    >
      <div class="my-auto">{{ placeholder }}</div>
    </div>
    <input
      #searchInput
      required
      autofocus
      formFocus
      [initialFocus]="initialFocus"
      class="e-input"
      [ngClass]="{
        hidden:
          (hideInput && showResult && !multiple) || (!enabled && !multiple)
      }"
      type="text"
      (keypress)="onIdentifierKeypress($event)"
      (blur)="onSearchInputBlur()"
      (focus)="searchInput.value = ''"
      [disabled]="!enabled"
    />
  </sol-textbox>
  <button
    class="ml-[-1px] mt-6 h-min rounded-l-none"
    ejs-button
    (click)="selectorTargetDialog.show()"
    type="button"
    [disabled]="!enabled"
  >
    <i class="fa-kit fa-regular-cube-magnifying-glass gradient-icon"></i>
  </button>
  <!-- <div class="mt-auto pl-3 pt-1">
          <i
            *ngFor="let error of errors$ | async"
            style="color: red"
            class="block text-sm"
            >{{ error }}</i
          >
        </div> -->
</div>
<ejs-dialog
  #selectorTargetDialog
  [showCloseIcon]="true"
  [header]="header"
  width="600"
  [visible]="false"
  isModal="true"
  (overlayClick)="selectorTargetDialog.hide()"
  (close)="onTouched(); searchValue = null"
  [animationSettings]="{ effect: 'None' }"
>
  <ng-template #content>
    <ng-container *ngIf="selectorTargetDialog.visible">
      <sol-multi-selector
        *ngIf="
          searchType.includes('Trackingobject') &&
          searchType.includes('Location')
        "
        [selectorConfigs]="selectorConfigs"
        (itemSelect)="
          onItemSelected($event); multiple ? true : selectorTargetDialog.hide()
        "
        [searchValue]="searchValue"
      ></sol-multi-selector>
      <sol-selector
        *ngIf="
          searchType.includes('Trackingobject') &&
          !searchType.includes('Location')
        "
        [exceptedItems]="exceptedIds"
        [selectorConfig]="selectorConfigObject"
        (itemSelect)="
          onObjectSelected($event);
          multiple ? true : selectorTargetDialog.hide()
        "
        [searchValue]="searchValue"
      >
      </sol-selector>
      <sol-selector
        *ngIf="
          !searchType.includes('Trackingobject') &&
          searchType.includes('Location')
        "
        [exceptedItems]="exceptedIds"
        [selectorConfig]="selectorConfigLocation"
        (itemSelect)="
          onLocationSelected($event);
          multiple ? true : selectorTargetDialog.hide()
        "
        [searchValue]="searchValue"
      >
      </sol-selector>
      <sol-selector
        *ngIf="searchType.includes('Address')"
        [exceptedItems]="exceptedIds"
        [selectorConfig]="selectorConfigAddress"
        (itemSelect)="
          onAddressSelected($event);
          multiple ? true : selectorTargetDialog.hide()
        "
        [searchValue]="searchValue"
      >
      </sol-selector>
    </ng-container>
  </ng-template>
</ejs-dialog>
