import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { RoleGetDto } from 'src/dto/GetDtos/role-get-dto';
import { SystemRoleGetDto } from 'src/dto/GetDtos/system-role-get-dto';

export interface PermissionGroup {
  name: string;
  readPermissions: EnumPermissions[];
  writePermissions: EnumPermissions[];
  deletePermissions: EnumPermissions[];
}
export interface CheckedPermissionModel {
  roles: RoleGetDto[];
  systemRoles: SystemRoleGetDto[];
  userChecked: boolean;
}
export interface PermissionModel {
  name: string;
  readChecked: CheckedPermissionModel;
  readPermission?: EnumPermissions;
  writeChecked: CheckedPermissionModel;
  writePermission?: EnumPermissions;
  deleteChecked: CheckedPermissionModel;
  deletePermission?: EnumPermissions;
}
export const PermissionModels: PermissionModel[] = [
  {
    name: 'Rollen',
    readPermission: EnumPermissions.RoleRead,
    writePermission: EnumPermissions.RoleWrite,
    deletePermission: EnumPermissions.RoleDelete,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
  {
    name: 'Rollenrechte',
    readPermission: EnumPermissions.RolePermissionRead,
    writePermission: EnumPermissions.RolePermissionWrite,
    deletePermission: EnumPermissions.RolePermissionDelete,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
  {
    name: 'Benutzer',
    readPermission: EnumPermissions.UserRead,
    writePermission: EnumPermissions.UserWrite,
    deletePermission: EnumPermissions.UserDelete,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
  {
    name: 'Benutzer-Einzelrechte',
    readPermission: EnumPermissions.UserPermissionRead,
    writePermission: EnumPermissions.UserPermissionWrite,
    deletePermission: EnumPermissions.UserPermissionDelete,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
  {
    name: 'Benutzer-Rollen',
    readPermission: EnumPermissions.UserRoleRead,
    writePermission: EnumPermissions.UserRoleWrite,
    deletePermission: EnumPermissions.UserRoleDelete,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
  {
    name: 'Trackingobjekte',
    readPermission: EnumPermissions.TrackingobjectRead,
    writePermission: EnumPermissions.TrackingobjectWrite,
    deletePermission: EnumPermissions.TrackingobjectDelete,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
  {
    name: 'Transfers',
    readPermission: EnumPermissions.TransferRead,
    writePermission: EnumPermissions.TransferWrite,
    deletePermission: EnumPermissions.TransferDelete,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
  {
    name: 'Transporte',
    readPermission: EnumPermissions.TransportRead,
    writePermission: EnumPermissions.TransportWrite,
    deletePermission: EnumPermissions.TransportDelete,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
  {
    name: 'Touren',
    readPermission: EnumPermissions.TourRead,
    writePermission: EnumPermissions.TourWrite,
    deletePermission: EnumPermissions.TourDelete,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
  {
    name: 'Teams',
    readPermission: EnumPermissions.TeamRead,
    writePermission: EnumPermissions.TeamWrite,
    deletePermission: EnumPermissions.TeamDelete,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
  {
    name: 'Orte',
    readPermission: EnumPermissions.LocationRead,
    writePermission: EnumPermissions.LocationWrite,
    deletePermission: EnumPermissions.LocationDelete,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
  {
    name: 'Stammdaten',
    readPermission: EnumPermissions.CoreDataRead,
    writePermission: EnumPermissions.CoreDataWrite,
    deletePermission: EnumPermissions.CoreDataDelete,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
  {
    name: 'Konfiguration Auto-Events',
    readPermission: EnumPermissions.EventConfigurationRead,
    writePermission: EnumPermissions.EventConfigurationWrite,
    deletePermission: EnumPermissions.EventConfigurationDelete,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
  {
    name: 'Konfiguration Auto-Transfers',
    readPermission: EnumPermissions.TransferAutomationRead,
    writePermission: EnumPermissions.TransferAutomationWrite,
    deletePermission: EnumPermissions.TransferAutomationDelete,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
  {
    name: 'Benachrichtigungen',
    readPermission: EnumPermissions.NotificationRead,
    writePermission: EnumPermissions.NotificationWrite,
    deletePermission: EnumPermissions.NotificationDelete,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
  {
    name: 'Log-Einträge',
    readPermission: EnumPermissions.LogRead,
    writePermission: undefined,
    deletePermission: undefined,
    readChecked: { roles: [], systemRoles: [], userChecked: false },
    writeChecked: { roles: [], systemRoles: [], userChecked: false },
    deleteChecked: { roles: [], systemRoles: [], userChecked: false },
  },
];
export const PermissionGroups: PermissionGroup[] = [
  {
    name: 'Rollen',
    readPermissions: [
      EnumPermissions.RoleRead,
      EnumPermissions.RolePermissionRead,
    ],
    writePermissions: [
      EnumPermissions.RolePermissionWrite,
      EnumPermissions.RoleWrite,
    ],
    deletePermissions: [
      EnumPermissions.RoleDelete,
      EnumPermissions.RolePermissionDelete,
    ],
  },
  {
    name: 'Benutzer',
    readPermissions: [
      EnumPermissions.UserRead,
      EnumPermissions.UserPermissionRead,
      EnumPermissions.UserRoleRead,
    ],
    writePermissions: [
      EnumPermissions.UserWrite,
      EnumPermissions.UserPermissionWrite,
      EnumPermissions.UserRoleWrite,
    ],
    deletePermissions: [
      EnumPermissions.UserDelete,
      EnumPermissions.UserPermissionDelete,
      EnumPermissions.UserRoleDelete,
    ],
  },
  {
    name: 'Trackingobjekte',
    readPermissions: [
      EnumPermissions.TrackingobjectRead,
      EnumPermissions.TransferRead,
    ],
    writePermissions: [EnumPermissions.TrackingobjectWrite],
    deletePermissions: [EnumPermissions.TrackingobjectDelete],
  },
  {
    name: 'Transfers',
    readPermissions: [
      EnumPermissions.TrackingobjectRead,
      EnumPermissions.TransferRead,
    ],
    writePermissions: [
      EnumPermissions.TrackingobjectWrite,
      EnumPermissions.TransferWrite,
    ],
    deletePermissions: [EnumPermissions.TransferDelete],
  },
  {
    name: 'Transporte',
    readPermissions: [
      EnumPermissions.TrackingobjectRead,
      EnumPermissions.TourRead,
      EnumPermissions.TransportRead,
    ],
    writePermissions: [
      EnumPermissions.TourWrite,
      EnumPermissions.TransportWrite,
    ],
    deletePermissions: [
      EnumPermissions.TourDelete,
      EnumPermissions.TransportDelete,
    ],
  },
  {
    name: 'Teams',
    readPermissions: [EnumPermissions.TeamRead],
    writePermissions: [EnumPermissions.TeamWrite],
    deletePermissions: [EnumPermissions.TeamDelete],
  },
];
