import { SelectorConfig } from 'ngx-common-solution';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { LocationGetDto } from 'src/dto/GetDtos/location-get-dto';
import { LocationPostDto } from 'src/dto/PostDtos/location-post-dto';
import { LocationPutDto } from 'src/dto/PutDtos/location-put-dto';
import { LocationStoreService } from 'src/stores/location-store.service';

export function getDefaultSelectorConfigLocation(
  store: LocationStoreService,
): SelectorConfig<LocationGetDto, LocationPutDto, LocationPostDto> {
  return {
    id: 2,
    title: 'Orte',
    columnDefs: [
      {
        headerText: 'ID',
        valueFormatter: (item) => {
          return item.id;
        },
        type: 'number',
        searchPropertyName: 'id',
      },
      {
        headerText: 'Name',
        valueFormatter: (item) => {
          return item.name;
        },
        type: 'text',
        searchPropertyName: 'name',
      },
    ],
    searchStore$: store,
    permission: EnumPermissions.LocationRead,
  };
}
