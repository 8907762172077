import { SelectorConfig } from 'ngx-common-solution';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { TrackingobjectGetDto } from 'src/dto/GetDtos/trackingobject-get-dto';
import { TrackingobjectPostDto } from 'src/dto/PostDtos/trackingobject-post-dto';
import { TrackingobjectPutDto } from 'src/dto/PutDtos/trackingobject-put-dto';
import { TrackingobjectStoreService } from 'src/stores/trackingobject-store.service';

export function getDefaultSelectorConfigObject(
  objectStore: TrackingobjectStoreService,
): SelectorConfig<
  TrackingobjectGetDto,
  TrackingobjectPutDto,
  TrackingobjectPostDto
> {
  return {
    id: 1,
    title: 'Objekte',
    columnDefs: [
      {
        headerText: 'ID',
        valueFormatter: (item) => {
          return item.id;
        },
        type: 'number',
        searchPropertyName: 'id',
      },
      {
        headerText: 'Name',
        valueFormatter: (item) => {
          return item.displayName;
        },
        type: 'text',
        searchPropertyName: 'displayName',
      },
    ],
    searchStore$: objectStore,
    permission: EnumPermissions.TrackingobjectRead,
  };
}
export function getDefaultSelectorConfigObjectWithExternalIds(
  objectStore: TrackingobjectStoreService,
): SelectorConfig<
  TrackingobjectGetDto,
  TrackingobjectPutDto,
  TrackingobjectPostDto
> {
  return {
    id: 1,
    title: 'Objekte',
    columnDefs: [
      {
        headerText: 'ID',
        valueFormatter: (item) => {
          return item.id;
        },
        type: 'number',
        searchPropertyName: 'id',
      },
      {
        headerText: 'Name',
        valueFormatter: (item) => {
          return item.displayName;
        },
        type: 'text',
        searchPropertyName: 'displayName',
      },
      {
        headerText: 'Zusätzliche IDs',
        valueFormatter: (item: TrackingobjectGetDto) => {
          return item.trackingidentifiers.join(', ');
        },
        type: 'set',
        searchPropertyName: 'trackingidentifiers',
      },
    ],
    searchStore$: objectStore,
    permission: EnumPermissions.TrackingobjectRead,
  };
}
