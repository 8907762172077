import { Injectable } from '@angular/core';
import { LocationGetDto } from 'src/dto/GetDtos/location-get-dto';
import { LocationPutDto } from 'src/dto/PutDtos/location-put-dto';
import { LocationPostDto } from 'src/dto/PostDtos/location-post-dto';
import { TrackingobjectGetDto } from 'src/dto/GetDtos/trackingobject-get-dto';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'ngx-common-solution';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { GraphLocationInOutGetDto } from 'src/dto/GetDtos/graph-location-in-out-get-dto';
import { LocationTrackingobjectCountDto } from 'src/dto/GetDtos/location-trackingobject-count-dto';
import { LocationDashboardDataGetDto } from 'src/dto/GetDtos/location-dashboard-data-get-dto';

@Injectable({
  providedIn: 'root',
})
export class LocationService extends BaseService<
  LocationGetDto,
  LocationPutDto,
  LocationPostDto
> {
  constructor(http: HttpClient) {
    super(environment.apiBaseUrl, http, 'location');
  }
  getChildLocations(id: number): Observable<LocationGetDto[]> {
    return this.http.get<LocationGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/${id}/childlocations`,
    );
  }
  getChildTrackingobjects(id: number): Observable<TrackingobjectGetDto[]> {
    return this.http.get<TrackingobjectGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/${id}/childtrackingobjects`,
    );
  }
  getChildTrackingobjectsCount(
    id: number,
  ): Observable<LocationTrackingobjectCountDto> {
    return this.http.get<LocationTrackingobjectCountDto>(
      `${environment.apiBaseUrl}${this.path}/${id}/childtrackingobjects/count`,
    );
  }
  clearChildTrackingobjects(LocationId: number) {
    return this.http.post(
      `${environment.apiBaseUrl}${this.path}/${LocationId}/childtrackingobjects/clear`,
      {},
    );
  }
  getInOuts(id: number) {
    return this.http.get<GraphLocationInOutGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/${id}/inout`,
    );
  }
  getDashboardData(id: number) {
    return this.http.get<LocationDashboardDataGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/${id}/dashboarddata`,
    );
  }
}
