import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { of, catchError } from 'rxjs';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { TrackingobjectStoreService } from 'src/stores/trackingobject-store.service';
import {
  ComponentStateService,
  SelectItemEventArgs,
} from 'ngx-common-solution';
import { getDefaultSelectorConfigObjectWithExternalIds } from 'src/configs/trackingobject-selector-config';
import { LocationStoreService } from 'src/stores/location-store.service';
import { getDefaultSelectorConfigLocation } from 'src/configs/location-selector-config';
import { SearchModel } from 'src/services/util/search-model';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import {
  BarcodeSearchService,
  SearchType,
} from 'src/services/util/barcode-search.service';
import { LocationGetDto } from 'src/dto/GetDtos/location-get-dto';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TrackingobjectGetDto } from 'src/dto/GetDtos/trackingobject-get-dto';
import { AddressStoreService } from 'src/stores/address-store.service';
import { getDefaultSelectorConfigAddress } from 'src/configs/address-selector-config';
import { AddressGetDto } from 'src/dto/GetDtos/address-get-dto';
import { SearchInputModel } from '../search-input-model';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStateService],
})
export class SearchInputComponent
  implements ControlValueAccessor, AfterViewInit
{
  EP = EnumPermissions;
  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;
  @ViewChild('selectorTargetDialog') selectorTargetDialog?: DialogComponent;
  @Output() searchCompleted = new EventEmitter<SearchModel | undefined>();
  @Input() initialFocus? = true;
  @Input() placeholder?: string;
  @Input() header?: string;
  @Input() searchType: SearchType[] = ['Location', 'Trackingobject'];
  @Input() createUnknownObjects?: boolean = false;
  @Input() createNew?: boolean | null = false;
  @Input() enabled = true;
  @Input() multiple = false;
  @Input() exceptedIds: number[] = [];
  @Input() showResult = true;
  @Input() ngControl?: NgControl;
  @Input() set initialValue(initialValue: SearchInputModel | undefined) {
    if (initialValue) {
      this.writeValue(initialValue);
    }
  }

  selectorConfigObject = getDefaultSelectorConfigObjectWithExternalIds(
    this.objectStore,
  );
  selectorConfigLocation = getDefaultSelectorConfigLocation(this.locationStore);
  selectorConfigAddress = getDefaultSelectorConfigAddress(this.addressStore);
  selectorConfigs = [this.selectorConfigLocation, this.selectorConfigObject];

  searchValue: string | null = null;
  errors$;
  model?: SearchInputModel;
  hideInput?: boolean = true;
  isDisabled?: boolean;

  constructor(
    @Optional() @Self() _ngControl: NgControl,
    private objectStore: TrackingobjectStoreService,
    private locationStore: LocationStoreService,
    private addressStore: AddressStoreService,
    private componentState: ComponentStateService,
    private barcodeService: BarcodeSearchService,
  ) {
    this.errors$ = componentState.errors$;
    if (!!_ngControl) {
      _ngControl.valueAccessor = this;
    }
    this.ngControl = _ngControl;
  }
  ngAfterViewInit(): void {
    this.setHiddenStatus();
  }
  public focus() {
    if (this.searchInput) {
      this.searchInput.nativeElement.focus();
    }
  }
  onResetClick() {
    this.sendCompleted({
      trackingobject: undefined,
      location: undefined,
    });
  }
  onItemSelected(event: SelectItemEventArgs) {
    if (event.configId) {
      if (event.configId == this.selectorConfigObject.id) {
        this.sendCompleted({
          trackingobject: event.item,
          location: undefined,
        });
      } else {
        this.sendCompleted({
          location: event.item,
          trackingobject: undefined,
        });
      }
    }
  }
  onObjectSelected(object: TrackingobjectGetDto) {
    this.sendCompleted({
      trackingobject: object,
      location: undefined,
    });
  }
  onLocationSelected(location: LocationGetDto) {
    this.sendCompleted({
      trackingobject: undefined,
      location: location,
    });
  }
  onAddressSelected(address: AddressGetDto) {
    this.sendCompleted({
      trackingobject: undefined,
      location: undefined,
      address: address,
    });
  }
  sendCompleted(searchModel: SearchModel | undefined) {
    if (
      searchModel &&
      (searchModel.location ||
        searchModel?.trackingobject ||
        searchModel?.address)
    ) {
      const newModel: SearchInputModel = {
        locationId: searchModel.location?.id,
        locationName: searchModel.location?.name,
        trackingobjectId: searchModel.trackingobject?.id,
        trackingobjectName: searchModel.trackingobject?.displayName,
        addressId: searchModel.address?.id,
        addressName: searchModel.address?.name,
      };
      this.model = newModel;
    } else this.model = undefined;
    this.onChange(this.model);
    this.searchCompleted.emit(searchModel);
    this.setHiddenStatus();
  }
  searchForBarcode(barcode: string) {
    this.searchValue = null;
    if (barcode) {
      this.barcodeService
        .getSearchForBarcode(
          barcode,
          this.searchType,
          this.createNew ?? undefined,
        )
        .pipe(
          catchError((err) => {
            this.componentState.setErrors(['Nicht gefunden']);
            return of({
              error: 'Nicht gefunden',
              trackingobject: undefined,
              location: undefined,
            } as SearchModel);
          }),
        )
        .subscribe((o) => {
          if (o.error) {
            this.searchValue = barcode;
            this.selectorTargetDialog?.show();
          }
          this.sendCompleted(o);
          if (this.searchInput) {
            if ((!o || (!o.location && !o.trackingobject)) && !this.multiple) {
              this.searchInput.nativeElement.value = '';
              setTimeout(() => this.searchInput?.nativeElement.focus(), 1);
            }
            if (!this.showResult) {
              this.searchInput.nativeElement.value = '';
            }
          }
        });
    }
  }
  onIdentifierKeypress(e: KeyboardEvent) {
    if (e.key == 'Enter' && this.searchInput?.nativeElement.value) {
      this.searchForBarcode(this.searchInput.nativeElement.value);
    }
  }
  onValueChange(barcode: string) {
    this.searchForBarcode(barcode);
  }
  onLinkDivClick() {
    this.hideInput = false;
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
      setTimeout(() => this.searchInput?.nativeElement.focus(), 1);
    }
  }
  onSearchInputBlur() {
    if (
      this.searchInput?.nativeElement.value &&
      this.searchInput.nativeElement.value.length > 1
    ) {
      this.searchForBarcode(this.searchInput.nativeElement.value);
    }
    this.onTouched();
    this.setHiddenStatus();
  }
  setHiddenStatus() {
    if (this.searchInput) {
      if (this.model && !this.multiple) {
        this.hideInput = true;
        this.searchInput.nativeElement.value = ' ';
      } else {
        this.hideInput = false;
        if (this.placeholder) {
          this.hideInput = true;
        }
        this.searchInput.nativeElement.value = '';
      }
    }
  }
  writeValue(obj: SearchInputModel | undefined) {
    if (obj && (obj.locationId || obj?.trackingobjectId || obj?.addressId)) {
      var m: SearchInputModel = {};
      if (this.searchType.includes('Trackingobject')) {
        m = {
          trackingobjectId: obj.trackingobjectId,
          trackingobjectName: obj.trackingobjectName,
        };
      }
      if (this.searchType.includes('Location')) {
        m = {
          ...m,
          locationId: obj.locationId,
          locationName: obj.locationName,
        };
      }
      if (this.searchType.includes('Address')) {
        m = { ...m, addressId: obj.addressId, addressName: obj.addressName };
      }
      this.model = m;
    } else {
      this.model = undefined;
    }
    this.setHiddenStatus();
  }
  onChange: any = (value: any) => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
