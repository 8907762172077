import { Injectable } from '@angular/core';
import { BaseService } from 'ngx-common-solution';
import { TrackingobjectGetDto } from 'src/dto/GetDtos/trackingobject-get-dto';
import { TrackingobjectPutDto } from 'src/dto/PutDtos/trackingobject-put-dto';
import { TrackingobjectPostDto } from 'src/dto/PostDtos/trackingobject-post-dto';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, filter, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TrackingEventGetDto } from 'src/dto/GetDtos/tracking-event-get-dto';
import { RelatedTrackingobjectPostDto } from 'src/dto/PostDtos/related-trackingobject-post-dto';
import { GraphEventGetDto } from 'src/dto/GetDtos/graph-event-get-dto';
import { BulkPriorisationPostDto } from 'src/dto/PostDtos/bulk-priorisation-post-dto';
@Injectable({
  providedIn: 'root',
})
export class TrackingobjectService extends BaseService<
  TrackingobjectGetDto,
  TrackingobjectPutDto,
  TrackingobjectPostDto
> {
  constructor(http: HttpClient) {
    super(environment.apiBaseUrl, http, 'trackingobject');
  }
  getEventsById(id: number): Observable<TrackingEventGetDto[]> {
    return this.http.get<TrackingEventGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/${id}/trackingevents`,
    );
  }
  getEventsByTrackingIdentifier(
    trackingidentifier: string,
  ): Observable<TrackingEventGetDto[]> {
    return this.http.get<TrackingEventGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/trackingevents?trackingidentifier=${trackingidentifier}`,
    );
  }
  getGraphEventsById(id: number): Observable<GraphEventGetDto[]> {
    return this.http.get<GraphEventGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/${id}/graphevents`,
    );
  }
  getGraphEventsByTrackingIdentifier(
    trackingidentifier: string,
  ): Observable<GraphEventGetDto[]> {
    return this.http.get<GraphEventGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/graphevents?trackingidentifier=${trackingidentifier}`,
    );
  }
  getChildObjects(id: number): Observable<TrackingobjectGetDto[]> {
    return this.http.get<TrackingobjectGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/${id}/childtrackingobjects`,
    );
  }
  quickCreate(
    trackingidentifier: string,
  ): Observable<{ trackingobject: TrackingobjectGetDto; created: boolean }> {
    return this.http
      .post<TrackingobjectGetDto>(
        `${environment.apiBaseUrl}${this.path}/quick?trackingidentifier=${trackingidentifier}`,
        {},
        { observe: 'response' },
      )
      .pipe(
        filter(
          (response: HttpResponse<TrackingobjectGetDto>) => !!response.body,
        ),
        map((response: HttpResponse<TrackingobjectGetDto>) => {
          if (response.status == 201) {
            return {
              trackingobject: response.body!,
              created: true,
            };
          }
          return {
            trackingobject: response.body!,
            created: false,
          };
        }),
      );
  }
  getRelatedObjects(
    parentTrackingobjectId: number,
  ): Observable<TrackingobjectGetDto[]> {
    return this.http.get<TrackingobjectGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/${parentTrackingobjectId}/RelatedTrackingobjects`,
    );
  }
  createRelation(relatedPostDto: RelatedTrackingobjectPostDto) {
    return this.http.put(
      `${environment.apiBaseUrl}${this.path}/${relatedPostDto.parentTrackingobjectId}/RelatedTrackingobjects/${relatedPostDto.childTrackingobjectId}`,
      {},
    );
  }
  deleteRelation(relatedPostDto: RelatedTrackingobjectPostDto) {
    return this.http.delete(
      `${environment.apiBaseUrl}${this.path}/${relatedPostDto.parentTrackingobjectId}/RelatedTrackingobjects/${relatedPostDto.childTrackingobjectId}`,
      {},
    );
  }
  clearChildObjects(parentTrackingobjectId: number) {
    return this.http.post(
      `${environment.apiBaseUrl}${this.path}/${parentTrackingobjectId}/childtrackingobjects/clear`,
      {},
    );
  }
  bulkPrioritize(bulkpriorisationDto: BulkPriorisationPostDto) {
    return this.http.post(
      `${environment.apiBaseUrl}${this.path}/bulkpriorisation`,
      bulkpriorisationDto,
    );
  }
}
