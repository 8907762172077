import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationStoreService } from 'ngx-authentication';
import {
  Subject,
  BehaviorSubject,
  Observable,
  takeUntil,
  distinctUntilChanged,
  switchMap,
  of,
} from 'rxjs';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { SettingGetDto } from 'src/dto/GetDtos/setting-get-dto';
import { SettingService } from 'src/services/http/setting.service';

@Injectable({
  providedIn: 'root',
})
export class SettingStoreService implements OnDestroy {
  private onDestroy$ = new Subject();
  private _settings$ = new BehaviorSubject<SettingGetDto[]>([]);
  settings$: Observable<SettingGetDto[]> = this._settings$.asObservable();

  constructor(
    settingService: SettingService,
    authService: AuthenticationStoreService,
  ) {
    authService.isAuthenticated$
      .pipe(
        takeUntil(this.onDestroy$),
        distinctUntilChanged(),
        switchMap((isAuth) => (isAuth ? settingService.getSettings() : of([]))),
      )
      .subscribe((settings) => this.setSettings(settings));
  }
  setSettings(settings: SettingGetDto[]) {
    this._settings$.next(settings);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
