import { Injectable } from '@angular/core';
import { AddressGetDto } from 'src/dto/GetDtos/address-get-dto';
import { AddressPutDto } from 'src/dto/PutDtos/address-put-dto';
import { AddressPostDto } from 'src/dto/PostDtos/address-post-dto';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'ngx-common-solution';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AddressService extends BaseService<
  AddressGetDto,
  AddressPutDto,
  AddressPostDto
> {
  constructor(http: HttpClient) {
    super(environment.apiBaseUrl, http, 'address');
  }
}
