import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  distinctUntilChanged,
  shareReplay,
  tap,
} from 'rxjs';
import { SettingGetDto } from 'src/dto/GetDtos/setting-get-dto';
import { TeamSettingGetDto } from 'src/dto/GetDtos/team-setting-get-dto';
import { UserSettingGetDto } from 'src/dto/GetDtos/user-setting-get-dto';

@Injectable({
  providedIn: 'root',
})
export class SettingDetailsService {
  private _itemSetting$ = new BehaviorSubject<
    UserSettingGetDto | TeamSettingGetDto | undefined
  >(undefined);
  itemSetting$ = this._itemSetting$.asObservable().pipe(
    distinctUntilChanged(),
    tap((itemSetting) => (this.itemSetting = itemSetting)),
    shareReplay(1),
  );
  itemSetting: UserSettingGetDto | TeamSettingGetDto | undefined;
  private _excludedItemSettings$ = new BehaviorSubject<
    UserSettingGetDto[] | TeamSettingGetDto[]
  >([]);
  excludedItemSettings$ = this._excludedItemSettings$
    .asObservable()
    .pipe(distinctUntilChanged(), shareReplay(1));
  private _setting$: BehaviorSubject<SettingGetDto | undefined> =
    new BehaviorSubject<SettingGetDto | undefined>(undefined);
  setting$: Observable<SettingGetDto | undefined> = this._setting$
    .asObservable()
    .pipe(
      distinctUntilChanged(),
      tap((setting) => (this.setting = setting)),
      shareReplay(1),
    );
  setting: SettingGetDto | undefined;
  constructor() {}
  setItemSetting(
    itemSetting: UserSettingGetDto | TeamSettingGetDto | undefined,
  ) {
    this._itemSetting$.next(itemSetting);
  }
  setSetting(setting: SettingGetDto | undefined) {
    this._setting$.next(setting);
  }
  setExcludedSettings(settings: UserSettingGetDto[] | TeamSettingGetDto[]) {
    this._excludedItemSettings$.next(settings);
  }
}
