import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { PredicateModel } from '@syncfusion/ej2-angular-grids';
import { AuthenticationStoreService } from 'ngx-authentication';
import {
  BaseStoreService,
  FilterTranslatorService,
  UserPermissionsStoreService,
} from 'ngx-common-solution';
import { map, of, switchMap, take } from 'rxjs';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { TrackingobjectFilterDto } from 'src/dto/FilterDtos/trackingobject-filter-dto';
import { TrackingobjectGetDto } from 'src/dto/GetDtos/trackingobject-get-dto';
import { BulkPriorisationPostDto } from 'src/dto/PostDtos/bulk-priorisation-post-dto';
import { TrackingobjectPostDto } from 'src/dto/PostDtos/trackingobject-post-dto';
import { TrackingobjectPutDto } from 'src/dto/PutDtos/trackingobject-put-dto';
import { TrackingobjectService } from 'src/services/http/trackingobject.service';
@Injectable({
  providedIn: 'root',
})
export class TrackingobjectStoreService extends BaseStoreService<
  TrackingobjectGetDto,
  TrackingobjectPutDto,
  TrackingobjectPostDto
> {
  constructor(
    private service: TrackingobjectService,
    authenticationStore: AuthenticationStoreService,
    private filterTranslatorService: FilterTranslatorService,
    userPermissionsStore: UserPermissionsStoreService,
    translocoService: TranslocoService,
  ) {
    super(
      authenticationStore,
      service,
      userPermissionsStore,
      EnumPermissions.TrackingobjectRead,
      translocoService,
    );
  }

  getByTrackingidentifier(identifier: string) {
    return this.items$.pipe(
      take(1),
      switchMap((items) => {
        const item = items.find((i) =>
          i.trackingidentifiers.includes(identifier),
        );
        if (item) return of(item);
        else return of(undefined);
      }),
    );
  }

  bulkPrioritize(
    trackingidentifiers: string[],
    deleteExistingPriorisations: boolean,
  ) {
    const bulkpriorisationDto: BulkPriorisationPostDto = {
      deleteExistingPriorisations: deleteExistingPriorisations,
      trackingidentifiers: trackingidentifiers,
    };
    return this.service.bulkPrioritize(bulkpriorisationDto).pipe(
      switchMap((r) => {
        return this.items$;
      }),
      take(1),
      map((objects) => {
        return objects.map((o) => {
          if (
            trackingidentifiers.find((i) => o.trackingidentifiers.includes(i))
          ) {
            return { ...o, prioritized: true };
          }
          return o;
        });
      }),
    );
  }
  override getFilterPredicateModel(filterModels: PredicateModel[]) {
    var trackingobjectFilter: TrackingobjectFilterDto = {};
    filterModels?.forEach((filterModel: PredicateModel) => {
      switch (filterModel.field) {
        case 'id': {
          trackingobjectFilter.id =
            this.filterTranslatorService.getNumberFilterTypeByPredicateModel(
              filterModel,
            );
          break;
        }

        case 'displayName': {
          trackingobjectFilter.name =
            this.filterTranslatorService.getTextFilterTypeByPredicateModel(
              filterModel,
            );
          break;
        }
        case 'description': {
          trackingobjectFilter.description =
            this.filterTranslatorService.getTextFilterTypeByPredicateModel(
              filterModel,
            );
          break;
        }
        case 'locationPath': {
          trackingobjectFilter.locationPath =
            this.filterTranslatorService.getTextFilterTypeByPredicateModel(
              filterModel,
            );
          break;
        }
        case 'trackingobjectTypeId': {
          trackingobjectFilter.trackingobjectTypeId =
            this.filterTranslatorService.getSetFilterTypeByPredicateModel<number>(
              filterModel,
              trackingobjectFilter.trackingobjectTypeId,
            );
          break;
        }
        case 'currentLocationId': {
          trackingobjectFilter.currentLocationId =
            this.filterTranslatorService.getSetFilterTypeByPredicateModel<number>(
              filterModel,
              trackingobjectFilter.currentLocationId,
            );
          break;
        }
        case 'trackingobjectTypeLoadable': {
          trackingobjectFilter.trackingobjectTypeLoadable = {
            values: [filterModel.value as boolean],
          };
          break;
        }
        case 'prioritized': {
          trackingobjectFilter.prioritized = {
            values: [filterModel.value as boolean],
          };
          break;
        }
        case 'createdByUserId': {
          trackingobjectFilter.createdByUserId =
            this.filterTranslatorService.getSetFilterTypeByPredicateModel<number>(
              filterModel,
              trackingobjectFilter.createdByUserId,
            );
          break;
        }
        case 'createdOn': {
          trackingobjectFilter.createdOn =
            this.filterTranslatorService.getDateFilterTypeByPredicateModel(
              filterModel,
              trackingobjectFilter.createdOn,
            );
          break;
        }
        case 'trackingidentifier': {
          trackingobjectFilter.trackingidentifier =
            this.filterTranslatorService.getSetFilterTypeByPredicateModel<string>(
              filterModel,
              trackingobjectFilter.trackingidentifier,
            );
          break;
        }
      }
    });
    return Object.keys(trackingobjectFilter).length > 0
      ? trackingobjectFilter
      : undefined;
  }
}
