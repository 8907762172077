<form>
  <table class="w-96 gap-2">
    <tr class="w-full" *ngIf="!readOnly">
      <td class="w-full"></td>
      <td>
        <div class="e-btn-group">
          <label class="e-btn"
            >All
            <input
              type="checkbox"
              name="align"
              [value]="PermissionTypes.Read"
              (change)="onAllChange($event)"
              [checked]="allReadChecked"
              [readOnly]="readOnly"
          /></label>
        </div>
      </td>
      <td>
        <div class="e-btn-group">
          <label class="e-btn"
            >Alle
            <input
              type="checkbox"
              name="align"
              [value]="PermissionTypes.Write"
              (change)="onAllChange($event)"
              [checked]="allWriteChecked"
              [readOnly]="readOnly"
          /></label>
        </div>
      </td>
      <td>
        <div class="e-btn-group">
          <label class="e-btn"
            >Alle
            <input
              type="checkbox"
              name="align"
              [value]="PermissionTypes.Delete"
              (change)="onAllChange($event)"
              [checked]="allDeleteChecked"
              [readOnly]="readOnly"
          /></label>
        </div>
      </td>
    </tr>
    <tr class="w-full" *ngFor="let permission of permissionModels">
      <td class="w-full">
        <div class="whitespace-nowrap px-1">{{ permission.name }}</div>
      </td>
      <td>
        <div class="e-btn-group">
          <ng-container *ngIf="permission.readPermission">
            <input
              type="checkbox"
              [id]="permission.readPermission + 'read'"
              name="align"
              [value]="PermissionTypes.Read"
              [checked]="permission.readChecked.userChecked"
              [readOnly]="readOnly"
              (change)="
                onSinglePermissionChange($event, permission.readPermission)
              "
            />
            <label
              class="e-btn"
              [ngClass]="{
                'both-permission':
                  permission.readChecked.userChecked &&
                  (permission.readChecked.roles.length > 0 ||
                    permission.readChecked.systemRoles.length > 0),
                'user-permission':
                  permission.readChecked.userChecked &&
                  permission.readChecked.roles.length == 0 &&
                  permission.readChecked.systemRoles.length == 0,
                'role-permission':
                  !permission.readChecked.userChecked &&
                  (permission.readChecked.roles.length > 0 ||
                    permission.readChecked.systemRoles.length > 0)
              }"
              [for]="permission.readPermission + 'read'"
              >Lesen</label
            >
          </ng-container>
        </div>
      </td>
      <td>
        <div class="e-btn-group">
          <ng-container *ngIf="permission.writePermission">
            <input
              type="checkbox"
              [id]="permission.writePermission + 'write'"
              name="align"
              [value]="PermissionTypes.Write"
              [checked]="permission.writeChecked.userChecked"
              [readOnly]="readOnly"
              (change)="
                onSinglePermissionChange($event, permission.writePermission)
              "
            />
            <label
              class="e-btn"
              [ngClass]="{
                'both-permission':
                  permission.writeChecked.userChecked &&
                  (permission.writeChecked.roles.length > 0 ||
                    permission.writeChecked.systemRoles.length > 0),
                'user-permission':
                  permission.writeChecked.userChecked &&
                  permission.writeChecked.roles.length == 0 &&
                  permission.writeChecked.systemRoles.length == 0,
                'role-permission':
                  !permission.writeChecked.userChecked &&
                  (permission.writeChecked.roles.length > 0 ||
                    permission.writeChecked.systemRoles.length > 0)
              }"
              [for]="permission.writePermission + 'write'"
              >Schreiben/Ändern</label
            >
          </ng-container>
        </div>
      </td>
      <td>
        <div class="e-btn-group">
          <ng-container *ngIf="permission.deletePermission">
            <input
              type="checkbox"
              [id]="permission.deletePermission + 'delete'"
              name="align"
              [value]="PermissionTypes.Delete"
              [checked]="permission.deleteChecked.userChecked"
              [readOnly]="readOnly"
              (change)="
                onSinglePermissionChange($event, permission.deletePermission)
              "
            />
            <label
              class="e-btn"
              [ngClass]="{
                'both-permission':
                  permission.deleteChecked.userChecked &&
                  (permission.deleteChecked.roles.length > 0 ||
                    permission.deleteChecked.systemRoles.length > 0),
                'user-permission':
                  permission.deleteChecked.userChecked &&
                  permission.deleteChecked.roles.length == 0 &&
                  permission.deleteChecked.systemRoles.length == 0,
                'role-permission':
                  !permission.deleteChecked.userChecked &&
                  (permission.deleteChecked.roles.length > 0 ||
                    permission.deleteChecked.systemRoles.length > 0)
              }"
              [for]="permission.deletePermission + 'delete'"
              >Löschen</label
            >
          </ng-container>
        </div>
      </td>
    </tr>
  </table>
</form>
