import { Injectable } from '@angular/core';
import { catchError, map, of, switchMap, take } from 'rxjs';
import { LocationStoreService } from 'src/stores/location-store.service';
import { SearchModel } from './search-model';
import { TrackingobjectStoreService } from 'src/stores/trackingobject-store.service';
import { TrackingobjectService } from '../http/trackingobject.service';
import { showSuccessToast } from 'ngx-common-solution';
import { TrackingidentifierSearchService } from './trackingidentifier-search.service';

export type SearchType = 'Location' | 'Trackingobject' | 'Address' | 'User';
@Injectable({
  providedIn: 'root',
})
export class BarcodeSearchService {
  constructor(
    private locationStore: LocationStoreService,
    private objectStore: TrackingobjectStoreService,
    private objectService: TrackingobjectService,
    private identifierSearchService: TrackingidentifierSearchService,
  ) {}

  getSearchForBarcode(
    barcode: string,
    searchForType: SearchType[],
    createUnknownObjects: boolean = false,
  ) {
    return of(barcode).pipe(
      switchMap((barcode) =>
        this.determineSearchAction(
          barcode,
          searchForType,
          createUnknownObjects,
        ),
      ),
      catchError((err) => of({ error: 'Nicht gefunden' } as SearchModel)),
      take(1),
    );
  }

  private determineSearchAction(
    barcode: string,
    searchForType: SearchType[],
    createUnknownObjects: boolean,
  ) {
    const lowerBarcode = barcode.toLocaleLowerCase();
    const id = parseInt(barcode.split('.')[1]);

    if (lowerBarcode.startsWith('l.') && searchForType.includes('Location')) {
      return this.searchLocation(id);
    } else if (searchForType.includes('Trackingobject')) {
      if (lowerBarcode.startsWith('o.')) {
        return this.searchTrackingObject(id);
      } else if (createUnknownObjects) {
        return this.createTrackingObject(barcode);
      }
    }
    return this.searchByTrackingIdentifier(barcode);
  }

  private searchLocation(id: number) {
    if (id) {
      return this.locationStore
        .getSingle(id, false)
        .pipe(map((result) => ({ location: result }) as SearchModel));
    }
    return of({ error: 'Invalid Location ID' } as SearchModel);
  }

  private searchTrackingObject(id: number) {
    if (id) {
      return this.objectStore
        .getSingle(id, false)
        .pipe(map((result) => ({ trackingobject: result }) as SearchModel));
    }
    return of({ error: 'Invalid Trackingobject ID' } as SearchModel);
  }

  private createTrackingObject(barcode: string) {
    return this.objectService
      .quickCreate(barcode)
      .pipe(map((result) => this.handleCreationResult(result)));
  }

  private searchByTrackingIdentifier(barcode: string) {
    return this.identifierSearchService.getByTrackingidentifier(barcode).pipe(
      map(
        (result) =>
          ({
            trackingobject: result.trackingobject,
            location: result.location,
          }) as SearchModel,
      ),
    );
  }

  private handleCreationResult(result: any): SearchModel {
    if (result.created) {
      showSuccessToast();
    }
    this.objectStore.addOrUpdateItemGet(result.trackingobject);
    return { trackingobject: result.trackingobject } as SearchModel;
  }
}
