<app-search-input
  #searchInput
  *ngIf="enabled"
  (searchCompleted)="onSearchCompleted($event)"
  [exceptedIds]="(trackingobjectIds$ | async) ?? exceptedObjectIds"
  [multiple]="true"
  [enabled]="enabled"
  [createNew]="createNew"
  [initialFocus]="initialFocus"
  [searchType]="['Trackingobject']"
  header="Objekte suchen"
  [ngControl]="ngControl"
></app-search-input>
<div *ngIf="!enabled" class="mt-2">Objekte</div>
<div class="my-1 w-full" *ngIf="models">
  <div *ngFor="let model of models; index as i">
    <div>
      <span
        class="flex"
        *ngIf="model.trackingobjectId | getItem: objectStore | async as object"
      >
        <a
          class="m-auto ml-3 inline-flex w-full"
          [routerLink]="'/object/search'"
          [queryParams]="{ objectId: object.id }"
        >
          <strong title="{{ object.displayName }}">{{ object.displayName }}</strong>
        </a>
        <button
          *ngIf="additionalAction"
          ejs-button
          class="w-9"
          type="button"
          [ngClass]="{ actionedObject: model.additionalAction }"
          (click)="onAdditionalActionClick(model)"
          [title]="
            model.additionalAction
              ? additionalAction.tooltip?.confirmed
              : additionalAction.tooltip?.unconfirmed
          "
        >
          <div style="margin-left: -4px; font-size: large">
            <i
              class="{{ additionalAction.iconClass }}"
              style="{{ additionalAction.style }}"
            ></i>
          </div>
        </button>
        <button
          ejs-button
          *ngIf="!model.success && enabled"
          type="button"
          (click)="removeTrackingobject(object.id)"
        >
          <i class="fa-regular fa-xmark"></i>
        </button>
        <div *ngIf="model.error" class="inline">
          <button ejs-button (click)="onErrorClick(model.error)" type="button">
            FEHLER
            <i class="fa-solid fa-triangle-exclamation"></i>
          </button>
        </div>
        <div>
          <i
            class="fa-solid fa-check mx-2 align-text-bottom"
            *ngIf="model.success"
          ></i>
        </div>
      </span>
    </div>
  </div>
</div>
