import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AuthenticationStoreService } from 'ngx-authentication';
import {
  BaseStoreService,
  UserPermissionsStoreService,
} from 'ngx-common-solution';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { TeamGetDto } from 'src/dto/GetDtos/team-get-dto';
import { TeamPostDto } from 'src/dto/PostDtos/team-post-dto';
import { TeamPutDto } from 'src/dto/PutDtos/team-put-dto';
import { TeamService } from 'src/services/http/team.service';

@Injectable({
  providedIn: 'root',
})
export class TeamStoreService extends BaseStoreService<
  TeamGetDto,
  TeamPutDto,
  TeamPostDto
> {
  constructor(
    service: TeamService,
    authenticationStore: AuthenticationStoreService,
    userPermissionsStore: UserPermissionsStoreService,
    translocoService: TranslocoService,
  ) {
    super(
      authenticationStore,
      service,
      userPermissionsStore,
      EnumPermissions.TeamRead,
      translocoService,
    );
  }
}
