import { SelectorConfig } from 'ngx-common-solution';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { TrackingobjectTypeGetDto } from 'src/dto/GetDtos/trackingobject-type-get-dto';
import { TrackingobjectTypePostDto } from 'src/dto/PostDtos/trackingobject-type-post-dto';
import { TrackingobjectTypePutDto } from 'src/dto/PutDtos/trackingobject-type-put-dto';
import { TrackingobjectTypeStoreService } from 'src/stores/trackingobject-type-store.service';

export function getDefaultSelectorConfigObjectType(
  store: TrackingobjectTypeStoreService,
): SelectorConfig<
  TrackingobjectTypeGetDto,
  TrackingobjectTypePutDto,
  TrackingobjectTypePostDto
> {
  return {
    id: 1,
    title: 'Objekte',
    columnDefs: [
      {
        headerText: 'ID',
        valueFormatter: (item) => {
          return item.id;
        },
        type: 'number',
        searchPropertyName: 'id',
      },
      {
        headerText: 'Name',
        valueFormatter: (item) => {
          return item.name;
        },
        type: 'text',
        searchPropertyName: 'name',
      },
    ],
    searchStore$: store,
    permission: EnumPermissions.CoreDataRead,
  };
}
