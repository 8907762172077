import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  ColumnDef,
  ComponentStateService,
  Item,
  SelectorConfig,
} from 'ngx-common-solution';
import { UserStoreService } from 'src/stores/user-store.service';
import { ListViewComponent } from '@syncfusion/ej2-angular-lists';
import {
  Observable,
  Subject,
  distinctUntilChanged,
  finalize,
  map,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { UserGetDto } from 'src/dto/GetDtos/user-get-dto';
import { UserPutDto } from 'src/dto/PutDtos/user-put-dto';
import { UserPostDto } from 'src/dto/PostDtos/user-post-dto';
import { TeamService } from 'src/services/http/team.service';
import { TeamGetDto } from 'src/dto/GetDtos/team-get-dto';
import { TeamUserListService } from './team-user-list.service';
import { UserService } from 'src/services/http/user.service';
import { TeamStoreService } from 'src/stores/team-store.service';
import { Router } from '@angular/router';
import { getDefaultSelectorConfigTeam } from 'src/configs/team-selector-config';

@Component({
  selector: 'app-team-user-list',
  templateUrl: './team-user-list.component.html',
  styleUrls: ['./team-user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStateService],
})
export class TeamUserListComponent<TGetDto extends Item> implements OnDestroy {
  protected onDestroy$ = new Subject();
  EP = EnumPermissions;
  @ViewChild('list')
  listViewInstance?: ListViewComponent;
  fields = { text: 'name', id: 'id' };
  selectorConfig: any;
  @Input() set user(user: UserGetDto | undefined | null) {
    if (user) {
      this.context = 'User';
      this.detailsService.setItem(user as unknown as TGetDto);
      this.selectorConfig = this.selectorConfigTeam;
    }
  }
  @Input() set team(team: TeamGetDto | undefined | null) {
    if (team) {
      this.context = 'Team';
      this.detailsService.setItem(team as unknown as TGetDto);
      this.selectorConfig = this.selectorConfigUser;
    }
  }
  @Input() context?: 'Team' | 'User';
  item$: Observable<any>;
  teamUsers$: Observable<TGetDto[]>;
  teamUserIds$: Observable<number[]>;
  constructor(
    private componentState: ComponentStateService,
    private detailsService: TeamUserListService<TGetDto>,
    private userStore: UserStoreService,
    private teamStore: TeamStoreService,
    private teamService: TeamService,
    private userService: UserService,
    private router: Router,
  ) {
    this.item$ = detailsService.item$;
    this.teamUsers$ = detailsService.teamUsers$;
    this.teamUserIds$ = this.teamUsers$.pipe(
      map((teamUsers) => teamUsers.map((teamUser) => teamUser.id)),
    );
    this.item$
      .pipe(
        takeUntil(this.onDestroy$),
        distinctUntilChanged(),
        switchMap((item) => {
          if (item && this.context == 'Team')
            return teamService.getUsers(item.id);
          if (item && this.context == 'User')
            return userService.getTeams(item.id);
          else return of([]);
        }),
      )
      .subscribe((teamUsers) =>
        this.detailsService.setTeamUsers(teamUsers as unknown as TGetDto[]),
      );
  }
  selectorConfigUser: SelectorConfig<UserGetDto, UserPutDto, UserPostDto> = {
    id: 1,
    title: 'Benutzer',
    columnDefs: [
      {
        headerText: 'ID',
        valueFormatter: (item) => {
          return item.id;
        },
        type: 'number',
        searchPropertyName: 'id',
      },
      {
        headerText: 'Name',
        valueFormatter: (item) => {
          return item.firstName + ' ' + item.lastName;
        },
        type: 'text',
        searchPropertyName: 'name',
      },
    ] as ColumnDef<UserGetDto>[],
    searchStore$: this.userStore,
    permission: EnumPermissions.UserRead,
  };
  selectorConfigTeam = getDefaultSelectorConfigTeam(this.teamStore);
  onItemSelected(selectedItem: any, item: any) {
    this.componentState.setLoading(true);
    const team = this.context == 'Team' ? item : selectedItem;
    const user = this.context == 'User' ? item : selectedItem;
    this.teamService
      .addUser(team.id, user.id)
      .pipe(finalize(() => this.componentState.setLoading(false)))
      .subscribe((r) =>
        this.detailsService.addOrUpdateTeamUser(
          (this.context == 'Team' ? user : team) as unknown as TGetDto,
        ),
      );
  }
  onItemRemoved(selectedItem: any, item: any) {
    this.componentState.setLoading(true);
    const team = this.context == 'Team' ? item : selectedItem;
    const user = this.context == 'User' ? item : selectedItem;
    this.teamService
      .deleteUser(team.id, user.id)
      .pipe(finalize(() => this.componentState.setLoading(false)))
      .subscribe((r) =>
        this.detailsService.removeTeamUser(
          this.context == 'Team' ? user.id : team.id,
        ),
      );
  }
  onItemClicked(item: TGetDto) {
    if (this.context == 'Team') {
      this.router.navigate(['/user/' + item.id]);
    } else if (this.context == 'User') {
      this.router.navigate(['/team/' + item.id]);
    }
  }
  onDefaultSet(selectedItem: any, item: any) {
    this.componentState.setLoading(true);
    const team = this.context == 'Team' ? item : selectedItem;
    const user = (this.context == 'User' ? item : selectedItem) as UserGetDto;
    this.teamService
      .addUser(team.id, user.id, user.defaultTeamId != team.id)
      .pipe(finalize(() => this.componentState.setLoading(false)))
      .subscribe((r) => {
        const newUser: UserGetDto = {
          ...user,
          defaultTeamId: user.defaultTeamId != team.id ? team.id : undefined,
        };
        this.userStore.addOrUpdateItemGet(newUser);
        if (this.context == 'User') {
          this.detailsService.setItem(newUser as unknown as TGetDto);
        } else {
          this.detailsService.addOrUpdateTeamUser(
            newUser as unknown as TGetDto,
          );
        }
      });
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
