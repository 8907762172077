import { ColumnDef, SelectorConfig } from 'ngx-common-solution';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { TeamGetDto } from 'src/dto/GetDtos/team-get-dto';
import { TeamPostDto } from 'src/dto/PostDtos/team-post-dto';
import { TeamPutDto } from 'src/dto/PutDtos/team-put-dto';
import { TeamStoreService } from 'src/stores/team-store.service';

export function getDefaultSelectorConfigTeam(
  store: TeamStoreService,
): SelectorConfig<TeamGetDto, TeamPutDto, TeamPostDto> {
  return {
    id: 4,
    title: 'Teams',
    columnDefs: [
      {
        headerText: 'ID',
        valueFormatter: (item) => {
          return item.id;
        },
        type: 'number',
        searchPropertyName: 'id',
      },
      {
        headerText: 'Name',
        valueFormatter: (item) => {
          return item.name;
        },
        type: 'text',
        searchPropertyName: 'name',
      },
    ] as ColumnDef<TeamGetDto>[],
    searchStore$: store,
    permission: EnumPermissions.TeamRead,
  };
}
