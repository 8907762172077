<ejs-listview
  #list
  *ifPermission="[EP.TeamRead, EP.UserRead]"
  [fields]="fields"
  [dataSource]="teamUsers$ | async"
  showHeader="true"
  class="max-w-xs"
>
  <ng-template #headerTemplate>
    <div class="flex h-full">
      <div class="e-headertext flex">
        <span class="m-auto">{{
          context == "Team" ? "Mitglieder" : "Teams"
        }}</span>
      </div>
      <div class="ml-auto">
        <button
          [ifButtonPermission]="[EP.TeamWrite, EP.UserWrite]"
          class="mt-[2px]"
          (click)="selector.visible = true"
        >
          <i class="fa-solid fa-circle-plus text-2xl"></i>
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template #template let-data="">
    <div class="e-list-wrapper e-list-multi-line" *ngIf="item$ | async as item">
      <span class="e-list-item-header"></span>
      <span class="e-list-content flex w-full">
        <div class="w-full" (click)="onItemClicked(data)">
          {{ data.name ? data.name : data.firstName + " " + data.lastName }}
        </div>
        <button
          class="e-btn relative -top-1 ml-auto block px-[7px] py-0 hover:bg-primary-400"
          [ngClass]="{
            'bg-primary-400':
              (data.defaultTeamId && data.defaultTeamId == item.id) ||
              (item.defaultTeamId && item.defaultTeamId == data.id)
          }"
          type="button"
          (click)="onDefaultSet(data, item)"
        >
          Standard
        </button>
        <button
          ejs-button
          class="small-button"
          type="button"
          (click)="onItemRemoved(data, item)"
        >
          <i class="fa-solid fa-trash-can text-xl"></i>
        </button>
      </span>
    </div>
  </ng-template>
</ejs-listview>
<ejs-dialog
  #selector
  [showCloseIcon]="true"
  [header]="context == 'Team' ? 'User auswählen' : 'Team auswählen'"
  width="600"
  [visible]="false"
  isModal="true"
  (overlayClick)="selector.hide()"
>
  <ng-template #content>
    <ng-container *ngIf="selector.visible">
      <sol-selector
        *ngIf="item$ | async as item"
        [selectorConfig]="selectorConfig"
        [exceptedItems]="teamUserIds$ | async"
        (itemSelect)="onItemSelected($event, item)"
      >
      </sol-selector>
    </ng-container>
  </ng-template>
</ejs-dialog>
