import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import {
  CheckPermissionsDirective,
  EnumTranslatorPipe,
  IfPermissionDirective,
  IfButtonPermissionDirective,
  KeyValueEnumPipe,
  LoadingScreenComponent,
  NgxCommonSolutionModule,
  PermissionMissingComponent,
  SelectorComponent,
  TextboxComponent,
  GridToolbarComponent,
} from 'ngx-common-solution';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TranslocoModule } from '@ngneat/transloco';

const modules = [
  NgxCommonSolutionModule,
  ReactiveFormsModule,
  TranslocoModule,
  GridModule,
  DialogModule,
  TextBoxModule,
  ButtonModule,
  DropDownListModule,
  TextboxComponent,
  LoadingScreenComponent,
  KeyValueEnumPipe,
  EnumTranslatorPipe,
  SelectorComponent,
  CheckPermissionsDirective,
  IfPermissionDirective,
  IfButtonPermissionDirective,
  PermissionMissingComponent,
  GridToolbarComponent,
];
@NgModule({
  declarations: [],
  imports: [CommonModule, modules],
  exports: modules,
})
export class MyCommonModule {}
