import { SelectorConfig } from 'ngx-common-solution';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { AddressGetDto } from 'src/dto/GetDtos/address-get-dto';
import { AddressPostDto } from 'src/dto/PostDtos/address-post-dto';
import { AddressPutDto } from 'src/dto/PutDtos/address-put-dto';
import { AddressStoreService } from 'src/stores/address-store.service';

export function getDefaultSelectorConfigAddress(
  store: AddressStoreService,
): SelectorConfig<AddressGetDto, AddressPutDto, AddressPostDto> {
  return {
    id: 3,
    title: 'Adressen',
    columnDefs: [
      {
        headerText: 'ID',
        valueFormatter: (item) => {
          return item.id;
        },
        type: 'number',
        searchPropertyName: 'id',
      },
      {
        headerText: 'Name',
        valueFormatter: (item) => {
          return item.name;
        },
        type: 'text',
        searchPropertyName: 'name',
      },
    ],
    searchStore$: store,
    permission: EnumPermissions.CoreDataRead,
  };
}
