import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IdentifierSearchGetDto } from 'src/dto/GetDtos/identifier-search-get-dto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrackingidentifierService {
  private path = 'trackingidentifier/';
  constructor(private http: HttpClient) {}
  getByTrackingidentifier(
    trackingidentifier: string,
  ): Observable<IdentifierSearchGetDto> {
    return this.http.get<IdentifierSearchGetDto>(
      environment.apiBaseUrl + this.path + trackingidentifier,
    );
  }
}
