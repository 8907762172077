import { Injectable } from '@angular/core';
import { BaseService } from 'ngx-common-solution';
import { TeamGetDto } from 'src/dto/GetDtos/team-get-dto';
import { TeamPutDto } from 'src/dto/PutDtos/team-put-dto';
import { TeamPostDto } from 'src/dto/PostDtos/team-post-dto';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TeamSettingGetDto } from 'src/dto/GetDtos/team-setting-get-dto';
import { TeamSettingPutDto } from 'src/dto/PutDtos/team-setting-put-dto';
import { UserGetDto } from 'src/dto/GetDtos/user-get-dto';

@Injectable({
  providedIn: 'root',
})
export class TeamService extends BaseService<
  TeamGetDto,
  TeamPutDto,
  TeamPostDto
> {
  constructor(http: HttpClient) {
    super(environment.apiBaseUrl, http, 'team');
  }
  getSettings(teamId: number) {
    return this.http.get<TeamSettingGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/${teamId}/settings`,
    );
  }
  updateSetting(teamId: number, settingId: number, setting: TeamSettingPutDto) {
    return this.http.put(
      `${environment.apiBaseUrl}${this.path}/${teamId}/settings/${settingId}`,
      setting,
    );
  }
  deleteSetting(teamId: number, settingId: number) {
    return this.http.delete(
      `${environment.apiBaseUrl}${this.path}/${teamId}/settings/${settingId}`,
    );
  }
  getUsers(teamId: number) {
    return this.http.get<UserGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/${teamId}/user`,
    );
  }
  addUser(teamId: number, userId: number, isDefault?: boolean) {
    return this.http.put(
      `${environment.apiBaseUrl}${this.path}/${teamId}/user/${userId}${
        isDefault != undefined ? '?isDefault=' + isDefault : ''
      }`,
      {},
    );
  }
  deleteUser(teamId: number, userId: number) {
    return this.http.delete(
      `${environment.apiBaseUrl}${this.path}/${teamId}/user/${userId}`,
    );
  }
}
