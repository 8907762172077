import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamUserListComponent } from './team-user-list/team-user-list.component';
import { SettingDetailsComponent } from './setting-details/setting-details.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { PermissionSelectorComponent } from './permission-selector/permission-selector.component';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { MyCommonModule } from '../my-common/my-common.module';
import { ObjectAddMultipleComponent } from './object-add-multiple/object-add-multiple.component';
import { RouterModule } from '@angular/router';
import {
  FormFocusDirective,
  GetItemPipe,
  MultiSelectorComponent,
} from 'ngx-common-solution';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import {
  ColumnChooserService,
  ExcelExportService,
  FilterService,
  ForeignKeyService,
  PageService,
  PdfExportService,
  ReorderService,
  ResizeService,
  SelectionService,
  SortService,
  ToolbarService,
} from '@syncfusion/ej2-angular-grids';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TranslocoModule } from '@ngneat/transloco';

const components = [
  TeamUserListComponent,
  SettingDetailsComponent,
  SearchInputComponent,
  PermissionSelectorComponent,
  ObjectAddMultipleComponent,
];
@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    MyCommonModule,
    ListViewModule,
    RouterModule,
    MultiSelectorComponent,
    FormFocusDirective,
    GetItemPipe,
    SwitchModule,
    DateTimePickerModule,
    TranslocoModule,
  ],
  exports: components,
  providers: [
    ExcelExportService,
    PdfExportService,
    SelectionService,
    ToolbarService,
    ColumnChooserService,
    ReorderService,
    FilterService,
    PageService,
    SortService,
    ResizeService,
    ForeignKeyService,
  ],
})
export class SharedModule {}
