import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AuthenticationStoreService } from 'ngx-authentication';
import {
  BaseStoreService,
  UserPermissionsStoreService,
} from 'ngx-common-solution';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { AddressGetDto } from 'src/dto/GetDtos/address-get-dto';
import { AddressPostDto } from 'src/dto/PostDtos/address-post-dto';
import { AddressPutDto } from 'src/dto/PutDtos/address-put-dto';
import { AddressService } from 'src/services/http/address.service';

@Injectable({
  providedIn: 'root',
})
export class AddressStoreService extends BaseStoreService<
  AddressGetDto,
  AddressPutDto,
  AddressPostDto
> {
  constructor(
    authenticationStore: AuthenticationStoreService,
    service: AddressService,
    userPermissionsStore: UserPermissionsStoreService,
    translocoService: TranslocoService,
  ) {
    super(
      authenticationStore,
      service,
      userPermissionsStore,
      EnumPermissions.CoreDataRead,
      translocoService,
    );
  }
}
