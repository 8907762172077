import { Injectable } from '@angular/core';
import { of, switchMap, tap } from 'rxjs';
import { IdentifierSearchGetDto } from 'src/dto/GetDtos/identifier-search-get-dto';
import { LocationStoreService } from 'src/stores/location-store.service';
import { TrackingobjectStoreService } from 'src/stores/trackingobject-store.service';
import { TrackingidentifierService } from '../http/trackingidentifier.service';

@Injectable({
  providedIn: 'root',
})
export class TrackingidentifierSearchService {
  constructor(
    private trackingidentifierService: TrackingidentifierService,
    private trackingobjectStore: TrackingobjectStoreService,
    private locationStore: LocationStoreService,
  ) {}
  getByTrackingidentifier(identifier: string) {
    return this.trackingobjectStore.getByTrackingidentifier(identifier).pipe(
      switchMap((object) => {
        if (object) {
          return of({ trackingobject: object } as IdentifierSearchGetDto);
        } else {
          return this.locationStore.getByTrackingidentifier(identifier).pipe(
            switchMap((location) => {
              if (location) {
                return of({ location: location } as IdentifierSearchGetDto);
              } else {
                return this.trackingidentifierService
                  .getByTrackingidentifier(identifier)
                  .pipe(
                    tap((identiferSearch) => {
                      if (identiferSearch.trackingobject) {
                        this.trackingobjectStore.addOrUpdateItemGet(
                          identiferSearch.trackingobject,
                        );
                      }
                      if (identiferSearch.location) {
                        this.locationStore.addOrUpdateItemGet(
                          identiferSearch.location,
                        );
                      }
                    }),
                  );
              }
            }),
          );
        }
      }),
    );
  }
}
