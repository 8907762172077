import { Injectable } from '@angular/core';
import { BaseService } from 'ngx-common-solution';
import { UserGetDto } from 'src/dto/GetDtos/user-get-dto';
import { UserPutDto } from 'src/dto/PutDtos/user-put-dto';
import { UserPostDto } from 'src/dto/PostDtos/user-post-dto';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileGetDto } from 'src/dto/GetDtos/file-get-dto';
import { EnumPermissions } from 'src/dto/Enums/enum-permissions';
import { UserRoleGetDto } from 'src/dto/GetDtos/user-role-get-dto';
import { UserRolePutDto } from 'src/dto/PutDtos/user-role-put-dto';
import { UserPermissionGetDto } from 'src/dto/GetDtos/user-permission-get-dto';
import { UserPermissionPutDto } from 'src/dto/PutDtos/user-permission-put-dto';
import { UserSettingGetDto } from 'src/dto/GetDtos/user-setting-get-dto';
import { UserSettingPutDto } from 'src/dto/PutDtos/user-setting-put-dto';
import { TeamGetDto } from 'src/dto/GetDtos/team-get-dto';
import { UserTeamGetDto } from 'src/dto/GetDtos/user-team-get-dto';
import { NotificationGetDto } from 'src/dto/GetDtos/notification-get-dto';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService<
  UserGetDto,
  UserPutDto,
  UserPostDto
> {
  constructor(http: HttpClient) {
    super(environment.apiBaseUrl, http, 'user');
  }
  getCurrent(): Observable<UserGetDto> {
    return this.http.get<UserGetDto>(
      `${environment.apiBaseUrl}${this.path}/current`,
    );
  }
  getCurrentPermissions(): Observable<EnumPermissions[]> {
    return this.http.get<EnumPermissions[]>(
      `${environment.apiBaseUrl}${this.path}/current/permissions`,
    );
  }
  getCombinedPermissions(userId: number): Observable<EnumPermissions[]> {
    return this.http.get<EnumPermissions[]>(
      `${environment.apiBaseUrl}${this.path}/${userId}/combined-permissions`,
    );
  }
  getCurrentSettings(
    teamId: number | undefined | null,
  ): Observable<{ key: number; value: string }[]> {
    return this.http.get<{ key: number; value: string }[]>(
      `${environment.apiBaseUrl}${this.path}/current/settings${
        teamId ? '?teamId=' + teamId : ''
      }`,
    );
  }
  getCurrentTeams(): Observable<UserTeamGetDto[]> {
    return this.http.get<UserTeamGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/current/teams`,
    );
  }
  getRoles(userId: number): Observable<UserRoleGetDto> {
    return this.http.get<UserRoleGetDto>(
      `${environment.apiBaseUrl}${this.path}/${userId}/roles`,
    );
  }
  updateRoles(userId: number, roles: UserRolePutDto) {
    return this.http.put(
      `${environment.apiBaseUrl}${this.path}/${userId}/roles`,
      roles,
    );
  }
  getPermissions(userId: number): Observable<UserPermissionGetDto> {
    return this.http.get<UserPermissionGetDto>(
      `${environment.apiBaseUrl}${this.path}/${userId}/permissions`,
    );
  }
  updatePermissions(userId: number, permissions: UserPermissionPutDto) {
    return this.http.put(
      `${environment.apiBaseUrl}${this.path}/${userId}/permissions`,
      permissions,
    );
  }
  getSettings(userId: number) {
    return this.http.get<UserSettingGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/${userId}/settings`,
    );
  }
  updateSetting(userId: number, settingId: number, setting: UserSettingPutDto) {
    return this.http.put(
      `${environment.apiBaseUrl}${this.path}/${userId}/settings/${settingId}`,
      setting,
    );
  }
  deleteSetting(userId: number, settingId: number) {
    return this.http.delete(
      `${environment.apiBaseUrl}${this.path}/${userId}/settings/${settingId}`,
    );
  }

  getTeams(userId: number) {
    return this.http.get<TeamGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/${userId}/teams`,
    );
  }
  getCurrentNotifications() {
    return this.http.get<NotificationGetDto[]>(
      `${environment.apiBaseUrl}${this.path}/current/notifications`,
    );
  }
}
